import React from 'react';
import { useNavigate } from 'react-router-dom';
// Images
import Icon from '@mui/material/Icon';

import MDTypography from 'components/MDTypography';
import team3 from 'assets/images/demo1.png';

export default function Data() {
  const navigate = useNavigate();
  return {
    columns: [
      { Header: 'Thumbnail', accessor: 'adType', width: '10%', align: 'left' },
      { Header: 'Ad Name', accessor: 'adName', width: '10%', align: 'left' },
      { Header: 'Product', accessor: 'product', width: '10%', align: 'left' },

      { Header: 'Ad Type', accessor: 'image', width: '10%', align: 'left' },
      {
        Header: 'Aspect Ratio',
        accessor: 'aspectRatio',
        width: '10%',
        align: 'center',
      },
      {
        Header: 'Production Type',
        accessor: 'productionType',
        width: '10%',
        align: 'left',
      },
      {
        Header: 'Post Date',
        accessor: 'postDate',
        width: '10%',
        align: 'left',
      },
      {
        Header: 'Transcript',
        accessor: 'body',
        width: '15%',
        align: 'left',
      },

      {
        Header: 'Talent Demographics',
        accessor: 'talentDemographics',
        width: '10%',
        align: 'left',
      },
      {
        Header: 'Ad Frameworks',
        accessor: 'adFrameworks',
        width: '10%',
        align: 'left',
      },
      {
        Header: 'Key Selling Points',
        accessor: 'keySellingPoints',
        width: '15%',
        align: 'left',
      },
    ],

    rows: [
      {
        adName: (
          <MDTypography
            variant='text'
            fontWeight='medium'
            onClick={() => {
              navigate('/individual-ad-analysis/view');
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            Thrive
          </MDTypography>
        ),
        postDate: '06/01/2024',
        adType: (
          <img src={team3} name={name} size='sm' style={{ width: '50px' }} />
        ),
        campaignName: 'lorem',
        image: <Icon fontSize='medium'>smart_display</Icon>,
        body: 'I am obsessed with the shade Elena.\nJust look at that color payoff.\nIt is absolutely stunning.',
        aspectRatio: '16 x 9',
        product: 'Infinity Waterproof Eyeshadow Stick',
        productionType: 'Digital',
        talentDemographics: 'N/A',
        concept: 'Efficiency and productivity',
        adFrameworks: 'Call to Action',
        keySellingPoints:
          'User-friendly, Boost productivity, Team collaboration',
        url: `https://rickhouse-analysis.s3.amazonaws.com/Extra+Thrive+Data/_SocialProof_120201925257080716.mp4`,
      },

      {
        postDate: '06/01/2024',
        adType: (
          <img src={team3} name={name} size='sm' style={{ width: '50px' }} />
        ),
        adName: (
          <MDTypography
            variant='text'
            fontWeight='medium'
            onClick={() => {
              navigate('/individual-ad-analysis/view');
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            Thrive
          </MDTypography>
        ),
        adSetName: 'set name',
        campaignName: 'lorem',
        image: <Icon fontSize='medium'>smart_display</Icon>,
        body: 'Discover new music with Spotify.',
        product: 'Infinity Waterproof Eyeshadow Stick',
        aspectRatio: '1 x 1',
        productionType: 'Digital',
        talentDemographics: 'Young adults, diverse',
        concept: 'Music Discovery',
        adFrameworks: 'Lifestyle',
        keySellingPoints:
          'Wide music selection, Personalized recommendations, Cross-platform',
        url: `https://rickhouse-analysis.s3.amazonaws.com/Extra+Thrive+Data/_DiversifyTalentRepresentation_120205480450320716.mp4`,
      },
      {
        postDate: '06/01/2024',
        adType: (
          <img src={team3} name={name} size='sm' style={{ width: '50px' }} />
        ),
        adName: (
          <MDTypography
            variant='text'
            fontWeight='medium'
            onClick={() => {
              navigate('/individual-ad-analysis/view');
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            Thrive
          </MDTypography>
        ),
        adSetName: 'set name',
        campaignName: 'lorem',
        image: <Icon fontSize='medium'>smart_display</Icon>,

        body: 'Improve team communication with Slack.',
        product: 'Infinity Waterproof Eyeshadow Stick',
        aspectRatio: '3 x 2',
        productionType: 'Digital',
        talentDemographics: 'N/A',
        concept: 'Team Communication',
        adFrameworks: 'Call to Action',
        keySellingPoints: 'Real-time messaging, Integrations, Channels',
        url: `https://rickhouse-analysis.s3.amazonaws.com/483554067610532/120204834887630716/screenshots/120204834887630716_295604770270344_screenshot_1.png`,
      },
      {
        postDate: '06/01/2024',
        adType: (
          <img src={team3} name={name} size='sm' style={{ width: '50px' }} />
        ),
        adName: (
          <MDTypography
            variant='text'
            fontWeight='medium'
            onClick={() => {
              navigate('/individual-ad-analysis/view');
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            Thrive
          </MDTypography>
        ),
        adSetName: '-',
        campaignName: '-',
        image: <Icon fontSize='medium'>image</Icon>,

        body: 'Design better with InVision.',
        product: 'Infinity Waterproof Eyeshadow Stick',
        aspectRatio: '16 x 9',
        productionType: 'Digital',
        talentDemographics: 'Mixed age, gender, ethnicity',
        concept: 'Design Collaboration',
        adFrameworks: 'Problem-Solution',
        keySellingPoints: 'Design tools, Collaboration features, Prototyping',
        url: `https://rickhouse-analysis.s3.amazonaws.com/Extra+Thrive+Data/_DiversifyTalentRepresentation_120205480450320716.mp4`,
      },
      {
        postDate: '06/01/2024',
        adType: (
          <img src={team3} name={name} size='sm' style={{ width: '50px' }} />
        ),
        adName: (
          <MDTypography
            variant='text'
            fontWeight='medium'
            onClick={() => {
              navigate('/individual-ad-analysis/view');
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            Thrive
          </MDTypography>
        ),
        adSetName: 'set name',
        campaignName: 'lorem',
        image: <Icon fontSize='medium'>image</Icon>,
        body: 'Streamline your projects with Atlassian.',
        product: 'Infinity Waterproof Eyeshadow Stick',
        aspectRatio: '16 x 9',
        productionType: 'Digital',
        talentDemographics: 'N/A',
        concept: 'Organization and Efficiency',
        adFrameworks: 'Call to Action',
        keySellingPoints: 'Project management, Team collaboration, Efficiency',
        url: `https://rickhouse-analysis.s3.amazonaws.com/483554067610532/120204834887630716/screenshots/120204834887630716_295604770270344_screenshot_21.png`,
      },
      {
        postDate: '06/01/2024',
        adType: (
          <img src={team3} name={name} size='sm' style={{ width: '50px' }} />
        ),
        adName: (
          <MDTypography
            variant='text'
            fontWeight='medium'
            onClick={() => {
              navigate('/individual-ad-analysis/view');
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            Thrive
          </MDTypography>
        ),
        adSetName: 'set name',
        campaignName: 'lorem',
        image: <Icon fontSize='medium'>smart_display</Icon>,
        body: 'Discover new music with Spotify.',
        product: 'Infinity Waterproof Eyeshadow Stick',
        aspectRatio: '1 x 1',
        productionType: 'Digital',
        talentDemographics: 'Young adults, diverse',
        concept: 'Music Discovery',
        adFrameworks: 'Lifestyle',
        keySellingPoints:
          'Wide music selection, Personalized recommendations, Cross-platform',
        url: `https://rickhouse-analysis.s3.amazonaws.com/Extra+Thrive+Data/_SocialProof_120201925257080716.mp4`,
      },
      {
        postDate: '06/01/2024',
        adType: (
          <img src={team3} name={name} size='sm' style={{ width: '50px' }} />
        ),
        adName: (
          <MDTypography
            variant='text'
            fontWeight='medium'
            onClick={() => {
              navigate('/individual-ad-analysis/view');
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            Thrive
          </MDTypography>
        ),
        adSetName: 'set name',
        campaignName: 'lorem',
        image: <Icon fontSize='medium'>smart_display</Icon>,

        body: 'Improve team communication with Slack.',
        product: 'Infinity Waterproof Eyeshadow Stick',
        aspectRatio: '3 x 2',
        productionType: 'Digital',
        talentDemographics: 'N/A',
        concept: 'Team Communication',
        adFrameworks: 'Call to Action',
        keySellingPoints: 'Real-time messaging, Integrations, Channels',
        url: `https://rickhouse-analysis.s3.amazonaws.com/483554067610532/120204834887630716/screenshots/120204834887630716_295604770270344_screenshot_1.png`,
      },
      {
        postDate: '06/01/2024',
        adType: (
          <img src={team3} name={name} size='sm' style={{ width: '50px' }} />
        ),
        adName: (
          <MDTypography
            variant='text'
            fontWeight='medium'
            onClick={() => {
              navigate('/individual-ad-analysis/view');
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            Thrive
          </MDTypography>
        ),
        adSetName: '-',
        campaignName: '-',
        image: <Icon fontSize='medium'>image</Icon>,

        body: 'Design better with InVision.',
        product: 'Infinity Waterproof Eyeshadow Stick',
        aspectRatio: '16 x 9',
        productionType: 'Digital',
        talentDemographics: 'Mixed age, gender, ethnicity',
        concept: 'Design Collaboration',
        adFrameworks: 'Problem-Solution',
        keySellingPoints: 'Design tools, Collaboration features, Prototyping',
        url: `https://rickhouse-analysis.s3.amazonaws.com/Extra+Thrive+Data/_DiversifyTalentRepresentation_120205480450320716.mp4`,
      },
      {
        postDate: '06/01/2024',
        adType: (
          <img src={team3} name={name} size='sm' style={{ width: '50px' }} />
        ),
        adName: (
          <MDTypography
            variant='text'
            fontWeight='medium'
            onClick={() => {
              navigate('/individual-ad-analysis/view');
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            Thrive
          </MDTypography>
        ),
        adSetName: 'set name',
        campaignName: 'lorem',
        image: <Icon fontSize='medium'>image</Icon>,
        body: 'Streamline your projects with Atlassian.',
        product: 'Infinity Waterproof Eyeshadow Stick',
        aspectRatio: '16 x 9',
        productionType: 'Digital',
        talentDemographics: 'N/A',
        concept: 'Organization and Efficiency',
        adFrameworks: 'Call to Action',
        keySellingPoints: 'Project management, Team collaboration, Efficiency',
        url: `https://rickhouse-analysis.s3.amazonaws.com/Extra+Thrive+Data/_DiversifyTalentRepresentation_120205480450320716.mp4`,
      },
      {
        postDate: '06/01/2024',
        adType: (
          <img src={team3} name={name} size='sm' style={{ width: '50px' }} />
        ),
        adName: (
          <MDTypography
            variant='text'
            fontWeight='medium'
            onClick={() => {
              navigate('/individual-ad-analysis/view');
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            Thrive
          </MDTypography>
        ),
        adSetName: 'set name',
        campaignName: 'lorem',
        image: <Icon fontSize='medium'>smart_display</Icon>,
        body: 'Discover new music with Spotify.',
        product: 'Infinity Waterproof Eyeshadow Stick',
        aspectRatio: '1 x 1',
        productionType: 'Digital',
        talentDemographics: 'Young adults, diverse',
        concept: 'Music Discovery',
        adFrameworks: 'Lifestyle',
        keySellingPoints:
          'Wide music selection, Personalized recommendations, Cross-platform',
        url: `https://rickhouse-analysis.s3.amazonaws.com/483554067610532/120204834887630716/screenshots/120204834887630716_295604770270344_screenshot_1.png`,
      },
    ],
  };
}
