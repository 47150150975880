import validator from 'validator';

import { INVALID_EMAIL, REQUIRED_FIELD } from 'utils/messages';

function companyValidation(data) {
  const errors = {};

  if (validator.isEmpty(data.title.trim())) errors.title = REQUIRED_FIELD;
  if (validator.isEmpty(data.email.trim())) errors.email = REQUIRED_FIELD;
  else if (!validator.isEmail(data.email)) errors.email = INVALID_EMAIL;
  if (validator.isEmpty(data.industry.trim())) errors.industry = REQUIRED_FIELD;
  if (validator.isEmpty(data.description.trim()))
    errors.description = REQUIRED_FIELD;
  if (validator.isEmpty(data.appId.trim())) errors.appId = REQUIRED_FIELD;
  if (validator.isEmpty(data.appSecret.trim()))
    errors.appSecret = REQUIRED_FIELD;
  if (validator.isEmpty(data.appAccessToken.trim()))
    errors.appAccessToken = REQUIRED_FIELD;
  if (validator.isEmpty(data.adsAccountId.trim()))
    errors.adsAccountId = REQUIRED_FIELD;
  if (validator.isEmpty(data.appConfigId.trim()))
    errors.appConfigId = REQUIRED_FIELD;

  return { errors, isValid: Object.keys(errors).length <= 0 };
}

export default companyValidation;
