import axios from 'axios';

import { BASE_URL } from 'utils/config';
import { RESPONSE_CODE } from 'utils/constants';
import { errorToast } from 'utils/helper';

const refreshToken = async () => {
  try {
    const response = await axios.post(
      BASE_URL + '/api/v1/facebook-meta-ads/user/access-token',
      {
        refresh_token: localStorage.getItem('refresh_token'),
      }
    );

    localStorage.setItem('token', response.data.data.access_token);
    return response.data.data.access_token;
  } catch {
    window.location.href = '/sign-in'; // Redirect to login if refresh fails
    return false;
  }
};

const errorHandler = async (e) => {
  const statusCode =
    e?.response?.data?.meta?.status_code || e?.response?.data?.code;
  const message =
    e?.response?.data?.meta?.message || e?.response?.data?.message;

  switch (statusCode) {
    case RESPONSE_CODE.UNAUTHORIZED:
      try {
        // retry request
        const originalRequest = e.config;
        if (!originalRequest._retry) {
          originalRequest._retry = true;
          const newAccessToken = await refreshToken();
          if (newAccessToken) {
            originalRequest.headers[
              'Authorization'
            ] = `Bearer ${newAccessToken}`;
            return axios(originalRequest);
          }
        }
      } catch (error) {
        console.error('Refresh token failed:', error);
      }
      break;
    default:
      return errorToast(message, 'something');
  }
};

export default errorHandler;
