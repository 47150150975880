import React, { forwardRef } from 'react';
// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Custom styles for MDInput
import MDInputRoot from 'components/MDInput/MDInputRoot';

const MDInput = forwardRef(
  ({ error, success, disabled, require, ...rest }, ref) => (
    <MDInputRoot
      {...rest}
      ref={ref}
      ownerState={{ error, success, disabled, require }}
    />
  )
);

// Setting the display name for the MDInput component
MDInput.displayName = 'MDInput';

// Setting default values for the props of MDInput
MDInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the MDInput
MDInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  require: PropTypes.bool,
};

export default MDInput;
