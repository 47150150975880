import React, { useMemo } from 'react';
// navigator
import { useNavigate } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
// @mui material components
import { Card, Icon, List, ListItem } from '@mui/material';
import Grid from '@mui/material/Grid';
// charts
import VerticalBarChart from 'examples/Charts/BarCharts/VerticalBarChart';
import PieChart from 'examples/Charts/PieChart';
// common layout components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

// common components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDfilter from 'components/MDFilter';
import MDTypography from 'components/MDTypography';

// chart data
import {
  ageChartData,
  aspectChartData,
  genderChartData,
  productPieChartData,
  productTypePieChartData,
  uniqueIterationChartData,
  videoStaticChartData,
} from './data/chartData';

function Dashboard() {
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {/* common filter */}
      <MDfilter />

      <Card sx={{ height: '100%', padding: '20px', marginBottom: '24px' }}>
        <MDBox
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <MDBox>
            <MDTypography
              variant='h5'
              fontWeight='medium'
              textTransform='capitalize'
            >
              Top 3 Ways To Improve Hit Rate
            </MDTypography>
          </MDBox>
          <MDBox>
            <MDTypography
              variant='h6'
              fontWeight='bold'
              textTransform='capitalize'
              style={{
                background:
                  'linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))',
                display: 'inline',
                padding: '8px 14px',
                borderRadius: '20px',
                color: 'white',
              }}
            >
              Creative Opportunity Score: 80/100
            </MDTypography>
          </MDBox>
        </MDBox>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={6} lg={4}>
            <Card sx={{ padding: '10px 20px', height: '100%' }}>
              <Grid container spacing={2}>
                <Grid item sm={12} md={12} lg={8}>
                  {' '}
                  <MDBox>
                    <MDTypography
                      variant='h6'
                      fontWeight='medium'
                      textTransform='capitalize'
                    >
                      1. Integrate Social Proof
                    </MDTypography>
                    <MDTypography
                      variant='h6'
                      fontWeight='light'
                      color='text'
                      textTransform='capitalize'
                      sx={{ lineHeight: '1.2', marginTop: '10px' }}
                    >
                      Ads featuring social proof achieve 35% higher ROAS, but
                      only 23% of ads utilize this framework. Collaborate with
                      talent to create authentic testimonial content, aiming to
                      increase social proof ads to 35% of total ad volume.
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item sm={12} md={12} lg={4}>
                  <video
                    width='100%'
                    controls
                    src='https://rickhouse-analysis.s3.amazonaws.com/Extra+Thrive+Data/_CrueltyFree.mp4'
                    style={{
                      borderRadius: '0.75rem',
                      height: '200px',
                      objectFit: 'cover',
                    }}
                  ></video>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card sx={{ padding: '10px 20px', height: '100%' }}>
              <Grid container spacing={2}>
                <Grid item sm={12} md={12} lg={8}>
                  {' '}
                  <MDBox>
                    <MDTypography
                      variant='h6'
                      fontWeight='medium'
                      textTransform='capitalize'
                    >
                      2. Diversify Talent Representation
                    </MDTypography>
                    <MDTypography
                      variant='h6'
                      fontWeight='light'
                      color='text'
                      textTransform='capitalize'
                      sx={{ lineHeight: '1.2', marginTop: '10px' }}
                    >
                      Currently, 84.21% of talent featured in ads are Caucasian.
                      By increasing the representation of African American,
                      Asian, Hispanic/Latina, and other ethnic groups, we
                      attract a broader audience, enhance relatability and
                      reflect inclusivity.
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item sm={12} md={12} lg={4}>
                  <video
                    width='100%'
                    controls
                    src='https://rickhouse-analysis.s3.amazonaws.com/Extra+Thrive+Data/_DiversifyTalentRepresentation_120205480450320716.mp4'
                    style={{
                      borderRadius: '0.75rem',
                      height: '200px',
                      objectFit: 'cover',
                    }}
                  ></video>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card sx={{ padding: '10px 20px', height: '100%' }}>
              <Grid container spacing={2}>
                <Grid item sm={12} md={12} lg={8}>
                  {' '}
                  <MDBox>
                    <MDTypography
                      variant='h6'
                      fontWeight='medium'
                      textTransform='capitalize'
                    >
                      3. Highlight “Cruelty Free”
                    </MDTypography>
                    <MDTypography
                      variant='h6'
                      fontWeight='light'
                      color='text'
                      textTransform='capitalize'
                      sx={{ lineHeight: '1.2', marginTop: '10px' }}
                    >
                      ‘Cruelty Free’ focused ads are achieving 25% higher ROAS
                      on average compared to other hit ads, but this value
                      proposition is only represented in 4% of the total hit
                      ads.
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item sm={12} md={12} lg={4}>
                  <video
                    width='100%'
                    controls
                    src='https://rickhouse-analysis.s3.amazonaws.com/Extra+Thrive+Data/_SocialProof_120201925257080716.mp4'
                    style={{
                      borderRadius: '0.75rem',
                      height: '200px',
                      objectFit: 'cover',
                    }}
                  ></video>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          {/* see all recommendation button  */}
          <MDBox px={2}>
            <MDButton
              variant='gradient'
              color='info'
              sx={{ mt: 2 }}
              onClick={() => navigate('/top-recommendations')}
            >
              See all recommendations
            </MDButton>
          </MDBox>
        </Grid>
      </Card>

      <Card sx={{ marginTop: '20px', padding: '14px 12px' }}>
        <MDTypography variant='h5' fontWeight='medium' color='text'>
          May 2024 Performance & Creative Report
        </MDTypography>
      </Card>

      <Grid container spacing={3} pt={3}>
        <Grid item xs={12} md={6} lg={3}>
          <Card>
            <MDBox
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              py={2}
              px={2}
            >
              <MDBox
                variant='gradient'
                bgColor={'dark'}
                color={'light'}
                coloredShadow={'light'}
                borderRadius='xl'
                display='flex'
                justifyContent='center'
                alignItems='center'
                width='3rem'
                height='3rem'
              >
                <Icon fontSize='medium' color='inherit'>
                  view_in_ar
                </Icon>
              </MDBox>
              <MDBox textAlign='right' lineHeight={1.25}>
                <MDTypography variant='button' fontWeight='light' color='text'>
                  Total Ads With Delivery
                </MDTypography>
                <MDTypography variant='h4'>863</MDTypography>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card>
            <MDBox
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              py={2}
              px={2}
            >
              <MDBox
                variant='gradient'
                bgColor={'dark'}
                color={'light'}
                coloredShadow={'light'}
                borderRadius='xl'
                display='flex'
                justifyContent='center'
                alignItems='center'
                width='3rem'
                height='3rem'
              >
                <Icon fontSize='medium' color='inherit'>
                  trending_up
                </Icon>
              </MDBox>
              <MDBox textAlign='right' lineHeight={1.25}>
                <MDTypography variant='button' fontWeight='light' color='text'>
                  Hit Rate
                </MDTypography>
                <MDTypography variant='h4'>25.8%</MDTypography>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card>
            <MDBox
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              py={2}
              px={2}
            >
              <MDBox
                variant='gradient'
                bgColor={'dark'}
                color={'light'}
                coloredShadow={'light'}
                borderRadius='xl'
                display='flex'
                justifyContent='center'
                alignItems='center'
                width='3rem'
                height='3rem'
              >
                <Icon fontSize='medium' color='inherit'>
                  grading
                </Icon>
              </MDBox>
              <MDBox textAlign='right' lineHeight={1.25}>
                <MDTypography variant='button' fontWeight='light' color='text'>
                  Total Spend
                </MDTypography>
                <MDTypography variant='h4'>$8,672,259.61</MDTypography>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card>
            <MDBox
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              py={2}
              px={2}
            >
              <MDBox
                variant='gradient'
                bgColor={'dark'}
                color={'light'}
                coloredShadow={'light'}
                borderRadius='xl'
                display='flex'
                justifyContent='center'
                alignItems='center'
                width='3rem'
                height='3rem'
              >
                <Icon fontSize='medium' color='inherit'>
                  done_all
                </Icon>
              </MDBox>
              <MDBox textAlign='right' lineHeight={1.25}>
                <MDTypography variant='button' fontWeight='light' color='text'>
                  Total Results
                </MDTypography>
                <MDTypography variant='h4'>239,433</MDTypography>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>

      {/* category container */}
      <MDBox py={3}>
        <MDBox>
          <Grid container spacing={2}>
            {/* Talent Demographics */}
            <Grid item xs={12} md={12} lg={12}>
              <Card sx={{ height: '100%', padding: '20px' }}>
                <MDBox
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <MDBox>
                    <MDTypography
                      variant='h5'
                      fontWeight='medium'
                      textTransform='capitalize'
                    >
                      Talent Demographics
                    </MDTypography>
                  </MDBox>
                  <MDBox py={1} pr={2}>
                    <MDTypography
                      variant='h6'
                      fontWeight='bold'
                      textTransform='capitalize'
                      style={{
                        backgroundColor: 'yellow',
                        display: 'inline',
                        padding: '5px 10px',
                        borderRadius: '20px',
                        color: 'black',
                      }}
                    >
                      Score: &nbsp; 8/15
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox>
                  <MDBox display='flex' py={2} pr={2}>
                    <MDTypography
                      variant='button'
                      fontWeight='bold'
                      textTransform='capitalize'
                    >
                      How to Improve : &nbsp;
                    </MDTypography>
                  </MDBox>
                  <List>
                    <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                      <FiberManualRecordIcon
                        fontSize='4px'
                        sx={{ paddingRight: '10px' }}
                      />
                      <MDTypography
                        variant='button'
                        fontWeight='regular'
                        color='text'
                      >
                        To improve your score, consider increasing the ethnic
                        diversity of your talent pool. Currently, Caucasian
                        individuals dominate the representation at 84.21%.
                      </MDTypography>
                    </ListItem>
                    <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                      <FiberManualRecordIcon
                        fontSize='4px'
                        sx={{ paddingRight: '10px' }}
                      />
                      <MDTypography
                        variant='button'
                        fontWeight='regular'
                        color='text'
                      >
                        Aim to include more individuals from African American,
                        Asian, Hispanic/Latina, and other ethnic backgrounds to
                        ensure a broader and more inclusive representation.
                      </MDTypography>
                    </ListItem>
                    <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                      <FiberManualRecordIcon
                        fontSize='4px'
                        sx={{ paddingRight: '10px' }}
                      />
                      <MDTypography
                        variant='button'
                        fontWeight='regular'
                        color='text'
                      >
                        This will not only align better with the rubric but also
                        resonate more with a diverse customer base.
                      </MDTypography>
                    </ListItem>
                  </List>
                </MDBox>
                <MDBox pt={2} px={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={3}>
                      {useMemo(
                        () => (
                          <PieChart
                            icon={{ color: 'primary' }}
                            title='Age'
                            description=''
                            chart={ageChartData}
                            height='11.5rem'
                          />
                        ),
                        []
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      {useMemo(
                        () => (
                          <VerticalBarChart
                            icon={{}}
                            title='Ethnicity'
                            description=''
                            chart={{
                              labels: [
                                'Caucasian',
                                'African American',
                                'Mixed or Multiple Ethnicities',
                                'Multiracial or Hispanic/Latina',
                                'Asian',
                              ],
                              datasets: [
                                {
                                  label: 'Percentage',
                                  data: [84.21, 6.58, 5.26, 2.63, 1.32],
                                  backgroundColor: 'rgba(75,192,192,0.6)',
                                  borderColor: 'rgba(75,192,192,1)',
                                  borderWidth: 1,
                                },
                              ],
                            }}
                            height='11.5rem'
                          />
                        ),
                        []
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      {useMemo(
                        () => (
                          <PieChart
                            icon={{}}
                            title='Gender'
                            description=''
                            chart={genderChartData}
                            height='11.5rem'
                            style={{ boxShadow: 'none' }}
                          />
                        ),
                        []
                      )}
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                      {useMemo(
                        () => (
                          <VerticalBarChart
                            icon={{}}
                            title='Notable Features'
                            description=''
                            chart={{
                              labels: [
                                'Other',
                                'Blonde hair, applying makeup',
                                'Silver hair, wearing makeup',
                                'Visible eye makeup ',
                                'Brown eyes, well-groomed eyebrows, mascara',
                                'Brown hair, blue eyes',
                                'Gray hair, wearing makeup',
                              ],
                              datasets: [
                                {
                                  label: 'Percentage',
                                  data: [
                                    55.26, 11.84, 10.53, 6.58, 6.58, 5.26, 3.95,
                                  ],
                                  backgroundColor: 'rgba(75,192,192,0.6)',
                                  borderColor: 'rgba(75,192,192,1)',
                                  borderWidth: 1,
                                },
                              ],
                            }}
                            height='11.5rem'
                            style={{ boxShadow: 'none' }}
                          />
                        ),
                        []
                      )}
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>

            {/* concepts cart */}
            <Grid item xs={12} md={12} lg={6}>
              <MDBox>
                <Card sx={{ height: '100%', padding: '20px' }}>
                  <MDBox>
                    <MDBox
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <MDBox pt={2}>
                        <MDTypography
                          variant='h5'
                          fontWeight='medium'
                          textTransform='capitalize'
                        >
                          Concepts
                        </MDTypography>
                      </MDBox>
                      <MDBox py={1} pr={2}>
                        <MDTypography
                          variant='h6'
                          fontWeight='bold'
                          textTransform='capitalize'
                          style={{
                            backgroundColor: 'Green',
                            display: 'inline',
                            padding: '5px 10px',
                            borderRadius: '20px',
                            color: 'white',
                          }}
                        >
                          Score: &nbsp; 16/20
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <Grid container spacing={2} width={'100%'}>
                      <Grid item xs={12} md={6} lg={6}>
                        <MDBox py={1} pr={2}>
                          <MDTypography
                            variant='h6'
                            fontWeight='bold'
                            textTransform='capitalize'
                          >
                            Top Frameworks : &nbsp;
                          </MDTypography>
                        </MDBox>
                        <List>
                          <ListItem
                            sx={{ display: 'flex', alignItems: 'start' }}
                          >
                            <FiberManualRecordIcon
                              fontSize='4px'
                              sx={{ paddingRight: '10px' }}
                            />
                            <MDTypography
                              variant='button'
                              fontWeight='medium'
                              color='text'
                            >
                              Demonstration (33%) :&nbsp;
                              <MDTypography
                                variant='button'
                                fontWeight='regular'
                                color='text'
                              >
                                This framework is heavily utilized across
                                various ads, showcasing the application and
                                effectiveness of products like the Brilliant Eye
                                Brightener, Liquid Lash Extensions Mascara, and
                                Instant Brow Fix Semi-Permanent Eyebrow Gel.
                              </MDTypography>
                            </MDTypography>
                          </ListItem>
                          <ListItem
                            sx={{ display: 'flex', alignItems: 'start' }}
                          >
                            <FiberManualRecordIcon
                              fontSize='4px'
                              sx={{ paddingRight: '10px' }}
                            />
                            <MDTypography
                              variant='button'
                              fontWeight='medium'
                              color='text'
                            >
                              Problem-Solution (29%) :&nbsp;
                              <MDTypography
                                variant='button'
                                fontWeight='regular'
                                color='text'
                              >
                                Many ads focus on identifying a common beauty
                                problem (e.g., graying eyebrows, lack of
                                hydration in lip products) and presenting Thrive
                                Cosmetics&apos; products as the solution.
                              </MDTypography>
                            </MDTypography>
                          </ListItem>
                          <ListItem
                            sx={{ display: 'flex', alignItems: 'start' }}
                          >
                            <FiberManualRecordIcon
                              fontSize='4px'
                              sx={{ paddingRight: '10px' }}
                            />
                            <MDTypography
                              variant='button'
                              fontWeight='medium'
                              color='text'
                            >
                              Social Proof (23%) :&nbsp;
                              <MDTypography
                                variant='button'
                                fontWeight='regular'
                                color='text'
                              >
                                User testimonials, before-and-after comparisons,
                                and endorsements are frequently used to build
                                trust and credibility for the products. -
                                Concepts: &apos;Shimmering Shades Discount
                                Offer&apos;, &apos;Color Matched Makeup
                                Ad&apos;, &apos;Color Revolution&apos;,
                                &apos;Mother&apos;s Day Offer&apos;, &apos;Eye
                                Color Match&apos;
                              </MDTypography>
                            </MDTypography>
                          </ListItem>
                          <ListItem
                            sx={{ display: 'flex', alignItems: 'start' }}
                          >
                            <FiberManualRecordIcon
                              fontSize='4px'
                              sx={{ paddingRight: '10px' }}
                            />
                            <MDTypography
                              variant='button'
                              fontWeight='medium'
                              color='text'
                            >
                              Before & After (14%) :&nbsp;
                              <MDTypography
                                variant='button'
                                fontWeight='regular'
                                color='text'
                              >
                                <br></br>Concepts: &apos;Sheer Strength™
                                Hydrating Lip Tint&apos;, &apos;Hydrating Lip
                                Tint Before and After&apos;, &apos;Eye Color
                                Match&apos;
                              </MDTypography>
                            </MDTypography>
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <MDBox py={1} pr={2}>
                          <MDTypography
                            variant='h6'
                            fontWeight='bold'
                            textTransform='capitalize'
                          >
                            Recommendations for Missing Frameworks : &nbsp;
                          </MDTypography>
                        </MDBox>
                        <List>
                          <ListItem
                            sx={{ display: 'flex', alignItems: 'start' }}
                          >
                            <FiberManualRecordIcon
                              fontSize='4px'
                              sx={{ paddingRight: '10px' }}
                            />
                            <MDTypography
                              variant='button'
                              fontWeight='medium'
                              color='text'
                            >
                              Behind-the-Scenes :&nbsp;
                              <MDTypography
                                variant='button'
                                fontWeight='regular'
                                color='text'
                              >
                                Create content that gives a behind-the-scenes
                                look at the product development process,
                                showcasing the brand&apos;s commitment to
                                quality and innovation. This can build a deeper
                                connection with the audience by humanizing the
                                brand.
                              </MDTypography>
                            </MDTypography>
                          </ListItem>
                          <ListItem
                            sx={{ display: 'flex', alignItems: 'start' }}
                          >
                            <FiberManualRecordIcon
                              fontSize='4px'
                              sx={{ paddingRight: '10px' }}
                            />
                            <MDTypography
                              variant='button'
                              fontWeight='medium'
                              color='text'
                            >
                              Founder Story :&nbsp;
                              <MDTypography
                                variant='button'
                                fontWeight='regular'
                                color='text'
                              >
                                Share the story of how Thrive Causemetics was
                                founded, including the challenges and
                                inspirations behind the brand. This can add an
                                emotional layer to the brand&apos;s narrative
                                and resonate with consumers on a personal level.
                              </MDTypography>
                            </MDTypography>
                          </ListItem>
                          <ListItem
                            sx={{ display: 'flex', alignItems: 'start' }}
                          >
                            <FiberManualRecordIcon
                              fontSize='4px'
                              sx={{ paddingRight: '10px' }}
                            />
                            <MDTypography
                              variant='button'
                              fontWeight='medium'
                              color='text'
                            >
                              Interactive :&nbsp;
                              <MDTypography
                                variant='button'
                                fontWeight='regular'
                                color='text'
                              >
                                Develop interactive content such as polls,
                                quizzes, or Q&A sessions that engage the
                                audience directly. This can increase user
                                engagement and provide valuable insights into
                                customer preferences and needs.
                              </MDTypography>
                            </MDTypography>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>

            {/* Value Propositions */}
            <Grid item xs={12} md={12} lg={6}>
              <Card sx={{ height: '100%', padding: '20px' }}>
                <MDBox>
                  <MDBox>
                    <MDBox
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <MDBox>
                        <MDTypography
                          variant='h5'
                          fontWeight='medium'
                          textTransform='capitalize'
                        >
                          Value Proposition
                        </MDTypography>
                      </MDBox>
                      <MDBox py={1} pr={2}>
                        <MDTypography
                          variant='h6'
                          fontWeight='bold'
                          textTransform='capitalize'
                          style={{
                            backgroundColor: 'green',
                            display: 'inline',
                            padding: '5px 10px',
                            borderRadius: '20px',
                            color: 'white',
                          }}
                        >
                          Score: &nbsp; 15/15
                        </MDTypography>
                      </MDBox>
                    </MDBox>

                    <MDBox display='flex' py={2} pr={2}>
                      <MDTypography
                        variant='button'
                        fontWeight='bold'
                        textTransform='capitalize'
                      >
                        How to Improve : &nbsp;
                      </MDTypography>
                    </MDBox>
                    <List>
                      <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                        <FiberManualRecordIcon
                          fontSize='4px'
                          sx={{ paddingRight: '10px' }}
                        />
                        <MDTypography
                          variant='button'
                          fontWeight='regular'
                          color='text'
                        >
                          To maintain and potentially improve your score,
                          continue to monitor the performance of each value
                          proposition category and adjust your messaging
                          strategy based on consumer feedback and engagement
                          metrics.
                        </MDTypography>
                      </ListItem>
                      <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                        <FiberManualRecordIcon
                          fontSize='4px'
                          sx={{ paddingRight: '10px' }}
                        />
                        <MDTypography
                          variant='button'
                          fontWeight='regular'
                          color='text'
                        >
                          {' '}
                          Additionally, consider exploring new value
                          propositions or sub-categories that may resonate with
                          your audience, such as sustainability or cruelty-free
                          aspects, to further diversify and strengthen your
                          brand messaging.
                        </MDTypography>
                      </ListItem>
                    </List>
                  </MDBox>

                  <MDBox variant='gradient' borderRadius='lg' width={'100%'}>
                    {useMemo(
                      () => (
                        <VerticalBarChart
                          icon={{}}
                          title=''
                          description=''
                          chart={{
                            labels: [
                              'Natural Look and Enhancement',
                              'Other',
                              'Application Ease',
                              'Long-lasting',
                              'Hydration and Nourishment',
                              'Color and Shade Variety',
                              'Promotional Offers',
                            ],
                            datasets: [
                              {
                                label: 'Ads',
                                data: [30, 19, 11, 11, 11, 9, 6],
                              },
                            ],
                          }}
                          height='14.5rem'
                        />
                      ),
                      []
                    )}
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>

            {/* Product Breakdown */}
            <Grid item xs={12} md={12} lg={6}>
              <Card sx={{ height: '100%', padding: '20px' }}>
                <MDBox>
                  <MDBox
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <MDBox pt={2}>
                      <MDTypography
                        variant='h5'
                        fontWeight='medium'
                        textTransform='capitalize'
                      >
                        Product Breakdown
                      </MDTypography>
                    </MDBox>
                    <MDBox py={1} pr={2}>
                      <MDTypography
                        variant='h6'
                        fontWeight='bold'
                        textTransform='capitalize'
                        style={{
                          backgroundColor: 'green',
                          display: 'inline',
                          padding: '5px 10px',
                          borderRadius: '20px',
                          color: 'white',
                        }}
                      >
                        Score: &nbsp; 10/10
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox display='flex' py={2} pr={2}>
                    <MDTypography
                      variant='button'
                      fontWeight='bold'
                      textTransform='capitalize'
                    >
                      How to Improve : &nbsp;
                    </MDTypography>
                  </MDBox>
                  <List>
                    <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                      <FiberManualRecordIcon
                        fontSize='4px'
                        sx={{ paddingRight: '10px' }}
                      />
                      <MDTypography
                        variant='button'
                        fontWeight='regular'
                        color='text'
                      >
                        To maintain and potentially enhance this strong
                        performance, continue to focus on the quality and
                        relevance of the promoted products.
                      </MDTypography>
                    </ListItem>
                    <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                      <FiberManualRecordIcon
                        fontSize='4px'
                        sx={{ paddingRight: '10px' }}
                      />
                      <MDTypography
                        variant='button'
                        fontWeight='regular'
                        color='text'
                      >
                        Consider leveraging data analytics to identify emerging
                        high performers and adjust promotional strategies
                        accordingly.
                      </MDTypography>
                    </ListItem>
                    <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                      <FiberManualRecordIcon
                        fontSize='4px'
                        sx={{ paddingRight: '10px' }}
                      />
                      <MDTypography
                        variant='button'
                        fontWeight='regular'
                        color='text'
                      >
                        Additionally, ensure that the promotional efforts are
                        evenly balanced across the core products to avoid
                        over-reliance on a few items.
                      </MDTypography>
                    </ListItem>
                  </List>
                </MDBox>
                <MDBox
                  variant='gradient'
                  bgColor=''
                  borderRadius='lg'
                  width={'100%'}
                >
                  {useMemo(
                    () => (
                      <PieChart
                        icon={{}}
                        title=''
                        description=''
                        chart={productPieChartData}
                        height='12.5rem'
                      />
                    ),
                    []
                  )}
                </MDBox>
              </Card>
            </Grid>

            {/* Production Types */}
            <Grid item xs={12} md={12} lg={6}>
              <Card sx={{ height: '100%', padding: '20px' }}>
                <MDBox>
                  <MDBox>
                    <MDBox
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <MDBox pt={2}>
                        <MDTypography
                          variant='h5'
                          fontWeight='medium'
                          textTransform='capitalize'
                        >
                          Production Types
                        </MDTypography>
                      </MDBox>
                      <MDBox py={1} pr={2}>
                        <MDTypography
                          variant='h6'
                          fontWeight='bold'
                          textTransform='capitalize'
                          style={{
                            backgroundColor: 'green',
                            display: 'inline',
                            padding: '5px 10px',
                            borderRadius: '20px',
                            color: 'white',
                          }}
                        >
                          Score: &nbsp; 10/10
                        </MDTypography>
                      </MDBox>
                    </MDBox>

                    <MDBox py={2} pr={2}>
                      <MDTypography
                        variant='button'
                        fontWeight='bold'
                        textTransform='capitalize'
                      >
                        How to Improve : &nbsp;
                      </MDTypography>
                    </MDBox>
                    <List>
                      <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                        <FiberManualRecordIcon
                          fontSize='4px'
                          sx={{ paddingRight: '10px' }}
                        />
                        <MDTypography
                          variant='button'
                          fontWeight='regular'
                          color='text'
                        >
                          To further enhance your marketing strategy, consider
                          increasing the proportion of UGC content slightly.
                          This will not only consolidate the authenticity and
                          relatability of your brand but also encourage more
                          user engagement and trust.
                        </MDTypography>
                      </ListItem>
                      <List>
                        <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                          <FiberManualRecordIcon
                            fontSize='4px'
                            sx={{ paddingRight: '10px' }}
                          />
                          <MDTypography
                            variant='button'
                            fontWeight='regular'
                            color='text'
                          >
                            Additionally, exploring new production types such as
                            Lifestyle or Animation could provide fresh
                            perspectives and further diversify your content
                            portfolio.
                          </MDTypography>
                        </ListItem>
                      </List>
                    </List>
                  </MDBox>
                  <MDBox>
                    <MDBox
                      variant='gradient'
                      bgColor=''
                      borderRadius='lg'
                      width={'100%'}
                      mt={'20px'}
                    >
                      {useMemo(
                        () => (
                          <PieChart
                            icon={{}}
                            title=''
                            description=''
                            chart={productTypePieChartData}
                            height='12.5rem'
                          />
                        ),
                        []
                      )}
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>

            {/* ad type */}
            <Grid item xs={12} md={12} lg={12}>
              <Card sx={{ height: '100%', padding: '20px' }}>
                <MDBox
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <MDBox>
                    <MDTypography
                      variant='h5'
                      fontWeight='medium'
                      textTransform='capitalize'
                    >
                      Ad Types
                    </MDTypography>
                  </MDBox>
                  <MDBox py={1} pr={2}>
                    <MDTypography
                      variant='h6'
                      fontWeight='bold'
                      textTransform='capitalize'
                      style={{
                        backgroundColor: 'green',
                        display: 'inline',
                        padding: '5px 10px',
                        borderRadius: '20px',
                        color: 'white',
                      }}
                    >
                      Score: &nbsp; 14/20
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox>
                  <MDBox display='flex' py={2} pr={2}>
                    <MDTypography
                      variant='button'
                      fontWeight='bold'
                      textTransform='capitalize'
                    >
                      How to Improve : &nbsp;
                    </MDTypography>
                  </MDBox>
                  <List>
                    <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                      <FiberManualRecordIcon
                        fontSize='4px'
                        sx={{ paddingRight: '10px' }}
                      />
                      <MDTypography
                        variant='button'
                        fontWeight='regular'
                        color='text'
                      >
                        To improve your score, consider increasing the diversity
                        of your ad formats by incorporating more image ads to
                        achieve a closer balance to the 65:35 video-to-image
                        ratio.
                      </MDTypography>
                    </ListItem>
                  </List>
                  <List>
                    <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                      <FiberManualRecordIcon
                        fontSize='4px'
                        sx={{ paddingRight: '10px' }}
                      />
                      <MDTypography
                        variant='button'
                        fontWeight='regular'
                        color='text'
                      >
                        Additionally, work on diversifying your aspect ratios,
                        particularly by including more 9x16 formats to optimize
                        for mobile and auto-placement.
                      </MDTypography>
                    </ListItem>
                  </List>
                  <List>
                    <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                      <FiberManualRecordIcon
                        fontSize='4px'
                        sx={{ paddingRight: '10px' }}
                      />
                      <MDTypography
                        variant='button'
                        fontWeight='regular'
                        color='text'
                      >
                        This will help you reach a broader audience and enhance
                        the effectiveness of your ad campaigns.
                      </MDTypography>
                    </ListItem>
                  </List>
                </MDBox>
                <Grid container spacing={2} mt={2}>
                  <Grid item xs={12} md={6} lg={4}>
                    {useMemo(
                      () => (
                        <PieChart
                          icon={{ color: 'light' }}
                          title='Videos vs Static Images'
                          description=''
                          chart={videoStaticChartData}
                          height='9.5rem'
                        />
                      ),
                      []
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    {useMemo(
                      () => (
                        <PieChart
                          icon='info'
                          title='Aspect Ratios'
                          description=''
                          chart={aspectChartData}
                          height='9.5rem'
                          style={{ boxShadow: 'none' }}
                        />
                      ),
                      []
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    {useMemo(
                      () => (
                        <PieChart
                          icon={{}}
                          title='Unique vs Iterations'
                          description=''
                          chart={uniqueIterationChartData}
                          height='9.5rem'
                          style={{ boxShadow: 'none' }}
                        />
                      ),
                      []
                    )}
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            {/* Visuals */}
            <Grid item xs={12} md={12} lg={12}>
              <Card sx={{ height: '100%', padding: '20px' }}>
                <MDBox>
                  <MDBox
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <MDBox>
                      <MDTypography
                        variant='h5'
                        fontWeight='medium'
                        textTransform='capitalize'
                      >
                        Visuals
                      </MDTypography>
                    </MDBox>
                    <MDBox py={1} pr={2}>
                      <MDTypography
                        variant='h6'
                        fontWeight='bold'
                        textTransform='capitalize'
                        style={{
                          backgroundColor: 'yellow',
                          display: 'inline',
                          padding: '5px 10px',
                          borderRadius: '20px',
                        }}
                      >
                        Score: &nbsp; 7/10
                      </MDTypography>
                    </MDBox>
                  </MDBox>

                  <MDBox display='flex' py={2} pr={2}>
                    <MDTypography
                      variant='button'
                      fontWeight='bold'
                      textTransform='capitalize'
                    >
                      How to Improve : &nbsp;
                    </MDTypography>
                  </MDBox>
                  <List>
                    <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                      <FiberManualRecordIcon
                        fontSize='4px'
                        sx={{ paddingRight: '10px' }}
                      />
                      <MDTypography
                        variant='button'
                        fontWeight='regular'
                        color='text'
                      >
                        To further improve the diversity of your visuals and
                        avoid ad fatigue, consider incorporating a wider range
                        of locations and lifestyles in your image ads.
                      </MDTypography>
                    </ListItem>
                    <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                      <FiberManualRecordIcon
                        fontSize='4px'
                        sx={{ paddingRight: '10px' }}
                      />
                      <MDTypography
                        variant='button'
                        fontWeight='regular'
                        color='text'
                      >
                        This could include showcasing your products being used
                        in different settings (e.g., at home, at work, outdoors)
                        and by a diverse group of people.
                      </MDTypography>
                    </ListItem>
                    <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                      <FiberManualRecordIcon
                        fontSize='4px'
                        sx={{ paddingRight: '10px' }}
                      />
                      <MDTypography
                        variant='button'
                        fontWeight='regular'
                        color='text'
                      >
                        Additionally, varying the presentation of your products,
                        such as featuring different product combinations or
                        highlighting unique use cases, can help keep the ads
                        fresh and engaging.
                      </MDTypography>
                    </ListItem>
                  </List>
                </MDBox>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={6}>
                    <MDBox
                      variant='gradient'
                      bgColor=''
                      borderRadius='lg'
                      width={'100%'}
                    >
                      {useMemo(
                        () => (
                          <VerticalBarChart
                            icon={{}}
                            title='Image'
                            description=''
                            chart={{
                              labels: [
                                'Cosmetic products',
                                'Makeup bag',
                                'Teal packaging',
                                'Product branding / names',
                                'Discount offer',
                              ],
                              datasets: [
                                {
                                  label: 'Percentage',
                                  data: [100, 30, 20, 20, 15],
                                  backgroundColor: 'rgba(75,192,192,0.6)',
                                  borderColor: 'rgba(75,192,192,1)',
                                  borderWidth: 1,
                                },
                              ],
                            }}
                            height='9.5rem'
                          />
                        ),
                        []
                      )}
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    {' '}
                    <MDBox
                      variant='gradient'
                      bgColor=''
                      borderRadius='lg'
                      width={'100%'}
                    >
                      {useMemo(
                        () => (
                          <VerticalBarChart
                            icon={{}}
                            title='Video'
                            description=''
                            chart={{
                              labels: [
                                'Text overlays',
                                'Close-up shots of eyes or face',
                                'Before and after comparisons',
                                'Product application demonstrations',
                                'Product packaging and branding',
                              ],
                              datasets: [
                                {
                                  label: 'Percentage',
                                  data: [85, 80, 75, 70, 65],
                                  backgroundColor: 'rgba(75,192,192,0.6)',
                                  borderColor: 'rgba(75,192,192,1)',
                                  borderWidth: 1,
                                },
                              ],
                            }}
                            height='9.5rem'
                          />
                        ),
                        []
                      )}
                    </MDBox>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
