import React from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Card, CardMedia, Divider, Grid, List, ListItem } from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

import MDBox from 'components/MDBox';
import MDfilter from 'components/MDFilter';
import MDTypography from 'components/MDTypography';

// Images

export default function Recommendation() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDfilter />

      <Card sx={{ padding: '10px 14px' }}>
        <MDTypography
          variant='h5'
          fontWeight='medium'
          textTransform='capitalize'
        >
          Strengths & Recommendations
        </MDTypography>
      </Card>

      <Card sx={{ padding: '10px 14px', marginTop: '20px' }}>
        <MDTypography
          variant='h5'
          fontWeight='medium'
          textTransform='capitalize'
          my={1}
          px={1}
        >
          What You’re Doing Well
        </MDTypography>
        <Grid container spacing={2} pt={1}>
          <Grid item xs={12} md={6} xl={4}>
            <Card
              sx={{
                padding: '10px 20px',
                backgroundColor: 'rgb(73, 163, 241)',
                color: 'white',
              }}
            >
              <MDTypography variant='h4' component='div' color='white'>
                Score
              </MDTypography>
              <MDTypography variant='h5' component='div' color='white'>
                80/100
              </MDTypography>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <Card sx={{ padding: '10px 20px', height: '100%' }}>
              <MDTypography variant='h5' component='div'>
                Talent Representation
              </MDTypography>
              <MDTypography
                variant='h6'
                fontWeight='light'
                component='div'
                sx={{ fontSize: '15px' }}
              >
                Variety of age groups and ethnicities
              </MDTypography>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <Card sx={{ padding: '10px 20px', height: '100%' }}>
              {' '}
              <MDTypography variant='h5' component='div'>
                Product Diversity
              </MDTypography>
              <MDTypography
                variant='h6'
                fontWeight='light'
                component='div'
                sx={{ fontSize: '15px' }}
              >
                Over 10 products are represented in ads
              </MDTypography>
            </Card>
          </Grid>
        </Grid>

        <MDTypography
          variant='h5'
          fontWeight='medium'
          textTransform='capitalize'
          mt={3}
          px={1}
        >
          How To Improve Hit Rate
        </MDTypography>
        <Card sx={{ height: '100%', padding: '20px', boxShadow: 'none' }}>
          <MDBox>
            <Card sx={{ height: '100%' }}>
              <Grid container>
                <Grid item xs={12} md={6} lg={8}>
                  <MDBox px={2}>
                    <MDTypography
                      variant='h4'
                      fontWeight='medium'
                      textTransform='capitalize'
                    >
                      1. Integrate Social Proof
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox display={'flex'} justifyContent={'end'}>
                    <MDTypography
                      variant='body2'
                      fontWeight='bold'
                      textTransform='capitalize'
                      style={{
                        background:
                          'linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))',
                        display: 'inline',
                        padding: '6px 12px',
                        borderRadius: '20px',
                        color: 'white',
                      }}
                    >
                      Estimated Impact: HIGH
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={12} lg={12} px={2}>
                  <MDTypography
                    variant='body2'
                    sx={{ fontSize: '17px' }}
                    fontWeight='medium'
                    textTransform='capitalize'
                    component='div'
                    mt={2}
                  >
                    Category: Value Propositions
                  </MDTypography>
                  <MDTypography variant='body2' sx={{ fontSize: '14px' }}>
                    Ads featuring social proof achieve 35% higher ROAS, but only
                    23% of ads utilize this framework. Collaborate with talent
                    to create authentic testimonial content, aiming to increase
                    social proof ads to 35% of total ad volume.
                  </MDTypography>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox p={2}>
                    <MDBox pr={2}>
                      <MDTypography
                        variant='button'
                        fontWeight='bold'
                        textTransform='capitalize'
                      >
                        Sample Messaging :
                      </MDTypography>
                    </MDBox>
                    <List>
                      <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                        <FiberManualRecordIcon
                          fontSize='4px'
                          sx={{ paddingRight: '10px' }}
                        />
                        <MDTypography
                          variant='button'
                          fontWeight='regular'
                          color='text'
                        >
                          Hook 1 “This instantly wakes up my look and stays put
                          all day, even after workouts!”
                        </MDTypography>
                      </ListItem>
                      <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                        <FiberManualRecordIcon
                          fontSize='4px'
                          sx={{ paddingRight: '10px' }}
                        />
                        <MDTypography
                          variant='button'
                          fontWeight='regular'
                          color='text'
                        >
                          Hook 2 “This look took me less than 10 minutes and
                          feels like i’m wearing nothing”
                        </MDTypography>
                      </ListItem>
                      <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                        <FiberManualRecordIcon
                          fontSize='4px'
                          sx={{ paddingRight: '10px' }}
                        />
                        <MDTypography
                          variant='button'
                          fontWeight='regular'
                          color='text'
                        >
                          Hook 3 “This cut my morning routine in half— it’s
                          waterproof and never flakes
                        </MDTypography>
                      </ListItem>
                    </List>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4} sx={{ display: 'flex' }}>
                  <Divider orientation='vertical' sx={{ ml: -2, mr: 3 }} />
                  <MDBox p={2}>
                    <MDBox pr={2}>
                      <MDTypography
                        variant='button'
                        fontWeight='bold'
                        textTransform='capitalize'
                      >
                        Sample Concepts :
                      </MDTypography>
                    </MDBox>
                    <List>
                      <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                        <FiberManualRecordIcon
                          fontSize='4px'
                          sx={{ paddingRight: '10px' }}
                        />
                        <MDTypography
                          variant='button'
                          fontWeight='regular'
                          color='text'
                        >
                          Produce a “5 minute challenge” video where you speak
                          to ease of use & time saving benefits
                        </MDTypography>
                      </ListItem>
                      <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                        <FiberManualRecordIcon
                          fontSize='4px'
                          sx={{ paddingRight: '10px' }}
                        />
                        <MDTypography
                          variant='button'
                          fontWeight='regular'
                          color='text'
                        >
                          Document a “full-day wear test” using a Thrive product
                          where you check in with live reactions at various
                          points to show longevity and performance
                        </MDTypography>
                      </ListItem>
                    </List>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={12} lg={4} sx={{ display: 'flex' }}>
                  <Divider orientation='vertical' sx={{ ml: -2, mr: 3 }} />
                  <MDBox display='flex' mb={2}>
                    <CardMedia
                      component='video'
                      image='https://rickhouse-analysis.s3.amazonaws.com/Extra+Thrive+Data/_DiversifyTalentRepresentation_120205480450320716.mp4'
                      alt='Paella dish'
                      height={'250'}
                      sx={{
                        height: '250px',
                        width: '170px',
                        objectFit: 'cover',
                      }}
                      controls
                    />
                    <CardMedia
                      component='video'
                      image='https://rickhouse-analysis.s3.amazonaws.com/Extra+Thrive+Data/_SocialProof_120201925257080716.mp4'
                      alt='Paella dish'
                      height={'250'}
                      sx={{
                        height: '250px',
                        width: '170px',
                        objectFit: 'cover',
                      }}
                      controls
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </Card>
            <Card sx={{ height: '100%', mt: '30px' }}>
              <Grid container>
                <Grid item xs={12} md={6} lg={8}>
                  <MDBox px={2}>
                    <MDTypography
                      variant='h4'
                      fontWeight='medium'
                      textTransform='capitalize'
                    >
                      2. Integrate Social Proof
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox display={'flex'} justifyContent={'end'}>
                    <MDTypography
                      variant='body2'
                      fontWeight='bold'
                      textTransform='capitalize'
                      style={{
                        background:
                          'linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))',
                        display: 'inline',
                        padding: '6px 12px',
                        borderRadius: '20px',
                        color: 'white',
                      }}
                    >
                      Estimated Impact: HIGH
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={12} lg={12} px={2}>
                  <MDTypography
                    variant='body2'
                    sx={{ fontSize: '17px' }}
                    fontWeight='medium'
                    textTransform='capitalize'
                    component='div'
                    mt={2}
                  >
                    Category: Value Propositions
                  </MDTypography>
                  <MDTypography variant='body2' sx={{ fontSize: '14px' }}>
                    Ads featuring social proof achieve 35% higher ROAS, but only
                    23% of ads utilize this framework. Collaborate with talent
                    to create authentic testimonial content, aiming to increase
                    social proof ads to 35% of total ad volume.
                  </MDTypography>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox p={2}>
                    <MDBox pr={2}>
                      <MDTypography
                        variant='button'
                        fontWeight='bold'
                        textTransform='capitalize'
                      >
                        Sample Messaging :
                      </MDTypography>
                    </MDBox>
                    <List>
                      <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                        <FiberManualRecordIcon
                          fontSize='4px'
                          sx={{ paddingRight: '10px' }}
                        />
                        <MDTypography
                          variant='button'
                          fontWeight='regular'
                          color='text'
                        >
                          Hook 1 “This instantly wakes up my look and stays put
                          all day, even after workouts!”
                        </MDTypography>
                      </ListItem>
                      <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                        <FiberManualRecordIcon
                          fontSize='4px'
                          sx={{ paddingRight: '10px' }}
                        />
                        <MDTypography
                          variant='button'
                          fontWeight='regular'
                          color='text'
                        >
                          Hook 2 “This look took me less than 10 minutes and
                          feels like i’m wearing nothing”
                        </MDTypography>
                      </ListItem>
                      <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                        <FiberManualRecordIcon
                          fontSize='4px'
                          sx={{ paddingRight: '10px' }}
                        />
                        <MDTypography
                          variant='button'
                          fontWeight='regular'
                          color='text'
                        >
                          Hook 3 “This cut my morning routine in half— it’s
                          waterproof and never flakes
                        </MDTypography>
                      </ListItem>
                    </List>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4} sx={{ display: 'flex' }}>
                  <Divider orientation='vertical' sx={{ ml: -2, mr: 3 }} />
                  <MDBox p={2}>
                    <MDBox pr={2}>
                      <MDTypography
                        variant='button'
                        fontWeight='bold'
                        textTransform='capitalize'
                      >
                        Sample Concepts :
                      </MDTypography>
                    </MDBox>
                    <List>
                      <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                        <FiberManualRecordIcon
                          fontSize='4px'
                          sx={{ paddingRight: '10px' }}
                        />
                        <MDTypography
                          variant='button'
                          fontWeight='regular'
                          color='text'
                        >
                          Produce a “5 minute challenge” video where you speak
                          to ease of use & time saving benefits
                        </MDTypography>
                      </ListItem>
                      <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                        <FiberManualRecordIcon
                          fontSize='4px'
                          sx={{ paddingRight: '10px' }}
                        />
                        <MDTypography
                          variant='button'
                          fontWeight='regular'
                          color='text'
                        >
                          Document a “full-day wear test” using a Thrive product
                          where you check in with live reactions at various
                          points to show longevity and performance
                        </MDTypography>
                      </ListItem>
                    </List>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={12} lg={4} sx={{ display: 'flex' }}>
                  <Divider orientation='vertical' sx={{ ml: -2, mr: 3 }} />
                  <MDBox display='flex' mb={2}>
                    <CardMedia
                      component='video'
                      image='https://rickhouse-analysis.s3.amazonaws.com/Extra+Thrive+Data/_DiversifyTalentRepresentation_120205480450320716.mp4'
                      alt='Paella dish'
                      height={'250'}
                      sx={{
                        height: '250px',
                        width: '170px',
                        objectFit: 'cover',
                      }}
                      controls
                    />
                    <CardMedia
                      component='video'
                      image='https://rickhouse-analysis.s3.amazonaws.com/Extra+Thrive+Data/_SocialProof_120201925257080716.mp4'
                      alt='Paella dish'
                      height={'250'}
                      sx={{
                        height: '250px',
                        width: '170px',
                        objectFit: 'cover',
                      }}
                      controls
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </Card>
            <Card sx={{ height: '100%', mt: '30px' }}>
              <Grid container>
                <Grid item xs={12} md={6} lg={8}>
                  <MDBox px={2}>
                    <MDTypography
                      variant='h4'
                      fontWeight='medium'
                      textTransform='capitalize'
                    >
                      3. Integrate Social Proof
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox display={'flex'} justifyContent={'end'}>
                    <MDTypography
                      variant='body2'
                      fontWeight='bold'
                      textTransform='capitalize'
                      style={{
                        background:
                          'linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))',
                        display: 'inline',
                        padding: '6px 12px',
                        borderRadius: '20px',
                        color: 'white',
                      }}
                    >
                      Estimated Impact: HIGH
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={12} lg={12} px={2}>
                  <MDTypography
                    variant='body2'
                    sx={{ fontSize: '17px' }}
                    fontWeight='medium'
                    textTransform='capitalize'
                    component='div'
                    mt={2}
                  >
                    Category: Value Propositions
                  </MDTypography>
                  <MDTypography variant='body2' sx={{ fontSize: '14px' }}>
                    Ads featuring social proof achieve 35% higher ROAS, but only
                    23% of ads utilize this framework. Collaborate with talent
                    to create authentic testimonial content, aiming to increase
                    social proof ads to 35% of total ad volume.
                  </MDTypography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox p={2}>
                    <MDBox pr={2}>
                      <MDTypography
                        variant='button'
                        fontWeight='bold'
                        textTransform='capitalize'
                      >
                        Sample Messaging :
                      </MDTypography>
                    </MDBox>
                    <List>
                      <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                        <FiberManualRecordIcon
                          fontSize='4px'
                          sx={{ paddingRight: '10px' }}
                        />
                        <MDTypography
                          variant='button'
                          fontWeight='regular'
                          color='text'
                        >
                          Hook 1 “This instantly wakes up my look and stays put
                          all day, even after workouts!”
                        </MDTypography>
                      </ListItem>
                      <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                        <FiberManualRecordIcon
                          fontSize='4px'
                          sx={{ paddingRight: '10px' }}
                        />
                        <MDTypography
                          variant='button'
                          fontWeight='regular'
                          color='text'
                        >
                          Hook 2 “This look took me less than 10 minutes and
                          feels like i’m wearing nothing”
                        </MDTypography>
                      </ListItem>
                      <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                        <FiberManualRecordIcon
                          fontSize='4px'
                          sx={{ paddingRight: '10px' }}
                        />
                        <MDTypography
                          variant='button'
                          fontWeight='regular'
                          color='text'
                        >
                          Hook 3 “This cut my morning routine in half— it’s
                          waterproof and never flakes
                        </MDTypography>
                      </ListItem>
                    </List>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4} sx={{ display: 'flex' }}>
                  <Divider orientation='vertical' sx={{ ml: -2, mr: 3 }} />
                  <MDBox p={2}>
                    <MDBox pr={2}>
                      <MDTypography
                        variant='button'
                        fontWeight='bold'
                        textTransform='capitalize'
                      >
                        Sample Concepts :
                      </MDTypography>
                    </MDBox>
                    <List>
                      <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                        <FiberManualRecordIcon
                          fontSize='4px'
                          sx={{ paddingRight: '10px' }}
                        />
                        <MDTypography
                          variant='button'
                          fontWeight='regular'
                          color='text'
                        >
                          Produce a “5 minute challenge” video where you speak
                          to ease of use & time saving benefits
                        </MDTypography>
                      </ListItem>
                      <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                        <FiberManualRecordIcon
                          fontSize='4px'
                          sx={{ paddingRight: '10px' }}
                        />
                        <MDTypography
                          variant='button'
                          fontWeight='regular'
                          color='text'
                        >
                          Document a “full-day wear test” using a Thrive product
                          where you check in with live reactions at various
                          points to show longevity and performance
                        </MDTypography>
                      </ListItem>
                    </List>
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={12} lg={4} sx={{ display: 'flex' }}>
                  <Divider orientation='vertical' sx={{ ml: -2, mr: 3 }} />
                  <MDBox display='flex' mb={2}>
                    <CardMedia
                      component='video'
                      image='https://rickhouse-analysis.s3.amazonaws.com/Extra+Thrive+Data/_DiversifyTalentRepresentation_120205480450320716.mp4'
                      alt='Paella dish'
                      height={'250'}
                      sx={{
                        height: '250px',
                        width: '170px',
                        objectFit: 'cover',
                      }}
                      controls
                    />
                    <CardMedia
                      component='video'
                      image='https://rickhouse-analysis.s3.amazonaws.com/Extra+Thrive+Data/_SocialProof_120201925257080716.mp4'
                      alt='Paella dish'
                      height={'250'}
                      sx={{
                        height: '250px',
                        width: '170px',
                        objectFit: 'cover',
                      }}
                      controls
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </Card>
          </MDBox>
        </Card>
      </Card>
    </DashboardLayout>
  );
}
