import React, { useMemo } from 'react';
// react-chartjs-2 components
import { Pie } from 'react-chartjs-2';
// @mui material components
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
// PieChart configurations
import configs from 'examples/Charts/PieChart/configs';
// porp-types is a library for typechecking of props
import PropTypes from 'prop-types';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

ChartJS.register(ArcElement, Tooltip, Legend);

function PieChart({
  icon,
  title,
  description,
  height,
  chart,
  backgroundColor,
  textColor,
}) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});

  const renderChart = (
    <MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
      {title || description ? (
        <MDBox display='flex' px={description ? 1 : 0} pt={description ? 1 : 0}>
          {icon.component && (
            <MDBox
              width='4rem'
              height='4rem'
              bgColor={icon.color || 'dark'}
              variant='gradient'
              coloredShadow={icon.color || 'dark'}
              borderRadius='xl'
              display='flex'
              justifyContent='center'
              alignItems='center'
              color='white'
              mt={-5}
              mr={2}
            >
              <Icon fontSize='medium'>{icon.component}</Icon>
            </MDBox>
          )}
          <MDBox mt={icon.component ? -2 : 0}>
            {title && (
              <MDTypography variant='h6' style={{ color: textColor }}>
                {title}
              </MDTypography>
            )}
            <MDBox mb={2}>
              <MDTypography component='div' variant='button' color='text'>
                {description}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      {useMemo(
        () => (
          <MDBox
            height={height}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <Pie data={data} options={options} />
          </MDBox>
        ),
        [chart, height]
      )}
    </MDBox>
  );

  return title || description ? (
    <Card style={{ background: backgroundColor }}>{renderChart}</Card>
  ) : (
    renderChart
  );
}

// Setting default values for the props of PieChart
PieChart.defaultProps = {
  icon: { color: 'info', component: '' },
  title: '',
  description: '',
  height: '25.125rem',
  backgroundColor: 'white',
  textColor: 'black',
};

// Typechecking props for the PieChart
PieChart.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'light',
      'dark',
    ]),
    component: PropTypes.node,
  }),
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object])
  ).isRequired,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
};

export default PieChart;
