import React, { useEffect } from 'react';
// react-router-dom components
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
// @mui material components
import List from '@mui/material/List';
// Material Dashboard 2 React context
import {
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  useMaterialUIController,
} from 'context';
// Material Dashboard 2 React example components
import SidenavCollapse from 'examples/Sidenav/SidenavCollapse';
// Custom styles for the Sidenav
import SidenavRoot from 'examples/Sidenav/SidenavRoot';
// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { clearLocalStorage } from 'utils/helper';
import rickHouseLogo from 'assets/images/logos/rickhouseLogo.png';

function Sidenav({ brand, routes, ...rest }) {
  const [open, setOpen] = React.useState({});
  const [company, setCompany] = React.useState('');

  const handleCompanyChange = (event) => {
    setCompany(event.target.value);
  };

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    sidenavColor,
  } = controller;
  const navigate = useNavigate();
  const location = useLocation();
  const collapseName = location.pathname.replace('/', '');
  const countries = [
    { code: 'AD', label: 'Thrive Causemetics', phone: '376' },
    { code: 'AE', label: 'Amazon', phone: '971' },
    { code: 'AF', label: 'Disney', phone: '93' },
    { code: 'AG', label: 'Adidas', phone: '1-268' },
  ];
  const closeSidenav = () => setMiniSidenav(dispatch, true);
  const handleLogout = () => {
    clearLocalStorage();
    navigate('/sign-in');
  };

  function handleClick(key) {
    setOpen((prevKeys) => ({
      ...prevKeys,
      [key]: !prevKeys[key],
    }));
  }

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav
      );
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener('resize', handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleMiniSidenav);
  }, [dispatch, location]);

  const renderRoutes = routes.map(
    ({ name, icon, key, route, subMenu = [] }) => {
      const isExpandable = subMenu.length > 0;
      if (isExpandable > 0) {
        return (
          <React.Fragment key={key}>
            <SidenavCollapse
              name={name}
              icon={icon}
              route={route}
              onClick={() => handleClick(key)}
              expandLess={isExpandable && open[key]}
              subMenu={true}
              parent={subMenu.some((subRoute) => subRoute.key === collapseName)}
            />
            <Collapse in={open[key]} timeout='auto' unmountOnExit key={key}>
              <MDBox px={2} py={1}>
                {subMenu.map(
                  ({
                    name: subName,
                    icon: subIcon,
                    key: subKey,
                    route: subRoute,
                  }) => (
                    <NavLink key={key} to={subRoute}>
                      <SidenavCollapse
                        name={subName}
                        icon={subIcon}
                        key={subKey}
                        route={subRoute}
                        active={collapseName.includes(subKey)}
                      />
                    </NavLink>
                  )
                )}
              </MDBox>
            </Collapse>
          </React.Fragment>
        );
      } else {
        return (
          <NavLink key={key} to={route}>
            <SidenavCollapse
              name={name}
              icon={icon}
              route={route}
              active={collapseName.includes(key)}
            />
          </NavLink>
        );
      }
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant='permanent'
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign='center'>
        <MDBox
          display={{ xs: 'block', xl: 'none' }}
          position='absolute'
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: 'pointer' }}
        >
          <MDTypography variant='h6' color='secondary'>
            <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to='/' display='flex' alignItems='center'>
          {brand && (
            <MDBox
              component='img'
              src={rickHouseLogo}
              alt='Brand'
              width='9rem'
            />
          )}
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />

      <MDBox px={2} py={1}>
        <FormControl
          sx={{ width: '100%', margin: '0px 10px 0px 0px' }}
          variant='outlined'
        >
          <InputLabel
            id='country-label'
            htmlFor='outlined-Name'
            style={{ height: '45px', color: '#ffffff' }}
          >
            Brand
          </InputLabel>

          <Select
            labelId='country-label'
            id='country'
            value={company}
            onChange={handleCompanyChange}
            label='Country'
            sx={{
              '& .MuiSelect-select': {
                height: '45px !important',
                color: '#ffffff',
              },
            }}
          >
            {countries.map((option) => (
              <MenuItem key={option.code} value={option.label}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </MDBox>

      <List>{renderRoutes}</List>
      <MDBox p={2} mt='auto'>
        <MDButton
          component='button'
          rel='noreferrer'
          variant='gradient'
          color={sidenavColor}
          fullWidth
          onClick={handleLogout}
        >
          Logout
        </MDButton>
      </MDBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: 'info',
  brand: '',
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
