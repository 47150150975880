import React from 'react';

import MDTypography from 'components/MDTypography';

export default function historyData() {
  const columns = [
    { Header: 'Month', accessor: 'month', width: '15%', align: 'left' },
    {
      Header: 'Calculated score / Total score',
      accessor: 'score',
      width: '25%',
      align: 'left',
    },
  ];

  const rows = [
    {
      month: 'January',
      score: <MDTypography variant='caption'>15/30</MDTypography>,
    },
    {
      month: 'February',
      score: <MDTypography variant='caption'>26/40</MDTypography>,
    },
    {
      month: 'March',
      score: <MDTypography variant='caption'>81/100</MDTypography>,
    },
    {
      month: 'April',
      score: <MDTypography variant='caption'>26/40</MDTypography>,
    },
    {
      month: 'May',
      score: <MDTypography variant='caption'>81/100</MDTypography>,
    },
  ];

  return { rows, columns };
}
