// @mui material components
import React from 'react';
import { useNavigate } from 'react-router-dom';
import FilterListIcon from '@mui/icons-material/FilterList';
import GridViewIcon from '@mui/icons-material/GridView';
import SortIcon from '@mui/icons-material/Sort';
import TableRowsIcon from '@mui/icons-material/TableRows';
import {
  CardContent,
  CardMedia,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'examples/Tables/DataTable';
// Data
import projectsTableData from 'layouts/individualAds/data/projectsTableData';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDfilter from 'components/MDFilter';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import logo from 'assets/images/logos/TC_logo_.png';

function Tables() {
  const { rows } = projectsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();
  const [alignment, setAlignment] = React.useState('list');
  const navigate = useNavigate();

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) setAlignment(newAlignment);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDfilter />

      <MDBox mt={2} display='flex' justifyContent='end' alignItems='center'>
        <MDInput
          placeholder='Search...'
          size='small'
          sx={{ marginRight: '10px' }}
        ></MDInput>
        <MDButton
          variant='outlined'
          color='info'
          cursor='pointer'
          size='small'
          startIcon={<FilterListIcon />}
        >
          Filter
        </MDButton>
        <MDButton
          variant='outlined'
          color='info'
          cursor='pointer'
          size='small'
          startIcon={<SortIcon />}
          sx={{ marginLeft: '10px' }}
        >
          Sort By
        </MDButton>

        <ToggleButtonGroup
          color='info'
          value={alignment}
          exclusive
          onChange={handleChange}
          aria-label='list view'
          sx={{ marginLeft: '20px' }}
        >
          <ToggleButton value='list'>
            <TableRowsIcon />
          </ToggleButton>
          <ToggleButton value='grid'>
            <GridViewIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </MDBox>

      {alignment === 'list' ? (
        <MDBox pt={5} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={2}
                  px={2}
                  variant='gradient'
                  bgColor='info'
                  borderRadius='lg'
                  coloredShadow='info'
                >
                  <MDTypography variant='h6' color='white'>
                    Ads Analysis
                  </MDTypography>
                </MDBox>
                <MDBox pt={1}>
                  <DataTable
                    table={{ columns: pColumns, rows: pRows }}
                    isSorted={false}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    canSearch={false}
                    noEndBorder={false}
                    customizeColumn={true}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      ) : (
        <MDBox pt={2} pb={3}>
          <Grid container spacing={2}>
            {rows.map((item, index) => (
              <Grid item xs={12} md={4} lg={2.4} key={index}>
                <Card
                  sx={{
                    maxWidth: 345,
                  }}
                >
                  <CardMedia
                    component={item.url.includes('.png') ? 'image' : 'video'}
                    sx={{ height: 280 }}
                    image={item.url}
                    alt='Paella dish'
                    controls
                  />
                  <CardContent
                    sx={{
                      padding: '0px 20px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center',
                    }}
                  >
                    <MDBox mt={1}>
                      <img
                        src={logo}
                        width={'35px'}
                        style={{ borderRadius: '50%', marginRight: '15px' }}
                        height={'35px'}
                      />
                    </MDBox>
                    <MDBox mt={2}>
                      <MDTypography
                        variant='text'
                        onClick={() => {
                          navigate('/individual-ad-analysis/view');
                        }}
                        sx={{
                          fontSize: '16px',
                          cursor: 'pointer',
                          lineHeight: '2px',
                        }}
                        fontWeight='medium'
                        display='block'
                      >
                        {item.adName}
                      </MDTypography>
                      <MDTypography
                        variant='text'
                        sx={{
                          fontSize: '13px',
                        }}
                      >
                        {item.product}
                      </MDTypography>
                    </MDBox>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default Tables;
