import React from 'react';
// Material Dashboard 2 React components
import { Grid } from '@mui/material';
import ReportsLineChart from 'examples/Charts/LineCharts/ReportsLineChart';
// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'examples/Tables/DataTable';
import reportsLineChartData from 'layouts/dashboard/data/reportsLineChartData';

import MDBox from 'components/MDBox';
import MDfilter from 'components/MDFilter';
import MDTypography from 'components/MDTypography';

import historyData from './data';

function History() {
  const { tasks } = reportsLineChartData;
  const { rows, columns } = historyData();

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDfilter />
      <MDBox pt={6} px={3}>
        <Grid container spacing={4}>
          <Grid item md={8}>
            <ReportsLineChart
              color='info'
              title='Opportunity Score History'
              description='Month wise Performance'
              date='just updated'
              chart={tasks}
            />
          </Grid>
          <Grid item md={4}>
            <MDBox mt={-4} width='100%'>
              <MDTypography variant='h6' gutterBottom>
                Month wise score history
              </MDTypography>
              <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                canSearch={false}
                noEndBorder={false}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default History;
