import React from 'react';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';

import MDBox from 'components/MDBox';

import NotFoundImage from '../../assets/images/illustrations/notFound.svg';

function NotFound() {
  return (
    <DashboardLayout>
      <MDBox
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        height='87vh'
        textAlign='center'
      >
        <img
          src={NotFoundImage}
          alt='404 Not Found'
          style={{ maxWidth: '30%', height: 'auto' }}
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default NotFound;
