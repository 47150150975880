import React from 'react';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import createdImg from 'assets/images/illustrations/created.svg';

export default function Success() {
  return (
    <MDBox display='flex' justifyContent='center' alignItems='center'>
      <MDBox>
        <MDBox
          mt={6}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <img src={createdImg} width={'50%'} />
        </MDBox>
        <MDBox
          mt={5}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <MDTypography
            align='center'
            variant='body1'
            fontWeight='regular'
            width='60%'
          >
            Congratulations! Your company is now configured and ready to soar.
            Let&apos;s make great things happen!
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}
