import React, { useEffect, useState } from 'react';
// react-router components
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import {
  setMiniSidenav,
  // setOpenConfigurator,
  useMaterialUIController,
} from 'context';
import Configurator from 'examples/Configurator';
// Material Dashboard 2 React example components
import Sidenav from 'examples/Sidenav';
import NotFound from 'layouts/404';
import routes from 'routes';
import ProtectedRoute from 'routes/ProtectedRoutes';

// Images
import brandWhite from 'assets/images/logos/rickhouseLogo.png';
import brandDark from 'assets/images/logos/rickhouseLogoBlack.png';
// Material Dashboard 2 React themes
import theme from 'assets/theme';
// Material Dashboard 2 React Dark Mode themes
import themeDark from 'assets/theme-dark';

// Material Dashboard 2 React routes
import navRoutes from './examples/Sidenav/SidenavData';

import 'App.css';

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    // openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  // const handleConfiguratorOpen = () =>
  //   setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if (route.private) {
          return (
            <Route
              exact
              key={route.key}
              path={route.route}
              element={<ProtectedRoute route={route}></ProtectedRoute>}
            >
              <Route
                exact
                path={route.route}
                element={route.component}
                key={route.key}
              ></Route>
            </Route>
          );
        }

        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  // const configsButton = (
  //   <MDBox
  //     display='flex'
  //     justifyContent='center'
  //     alignItems='center'
  //     width='3.25rem'
  //     height='3.25rem'
  //     bgColor='white'
  //     shadow='sm'
  //     borderRadius='50%'
  //     position='fixed'
  //     right='2rem'
  //     bottom='2rem'
  //     zIndex={99}
  //     color='dark'
  //     sx={{ cursor: 'pointer' }}
  //     onClick={handleConfiguratorOpen}
  //   >
  //     <Icon fontSize='small' color='inherit'>
  //       settings
  //     </Icon>
  //   </MDBox>
  // );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === 'dashboard' && pathname !== '/sign-in' && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? brandDark
                : brandWhite
            }
            brandName='RickHouse'
            routes={navRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      {layout === 'vr' && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path='/' exact element={<Navigate to='/dashboard' />} />
        <Route path='*' element={<NotFound></NotFound>} />
      </Routes>
    </ThemeProvider>
  );
}
