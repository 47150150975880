import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import api from 'api';
import PropTypes from 'prop-types';

import MDAlert from 'components/MDAlert';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import Loader from 'components/MDLoader';
import MDTypography from 'components/MDTypography';
import { RESPONSE_CODE } from 'utils/constants';

// import { successToast } from 'utils/helper';

function RubricsCategory({ handleStep, accountId }) {
  const [category, setCategory] = useState([]);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  // fetch rubric category list
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await api.rubricCategoryList();
      setCategory(response.data.data);
      setLoading(false);
    };
    fetchData();
  }, []);

  // do total based on the rubric category points
  useEffect(() => {
    const totalPoints = category.reduce(
      (acc, obj) => parseInt(acc + obj.total_points),
      0
    );
    setTotal(totalPoints);
  }, [category]);

  //handlers
  const handleInputChange = (index, field, value) => {
    const newData = [...category];
    newData[index][field] = parseInt(value);
    setCategory(newData);
  };
  const handleSwitchChange = (index, event) => {
    const newData = [...category];
    if (newData[index].is_active) newData[index]['total_points'] = 0;
    newData[index].is_active = event.target.checked;
    setCategory(newData);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // validation for the rubric category points
    if (total !== 100) {
      return setError(true);
    }

    setBtnLoading(true);
    // payload
    const payloadData = category.map((itm) => {
      return {
        rubric_categories_id: itm.id,
        total_points: itm.total_points,
        is_active: itm.is_active,
      };
    });
    const payloadBody = {
      data: payloadData,
    };
    const payloadParam = {
      acc_id: accountId,
    };

    const response = await api.addRubricCategory(payloadParam, payloadBody);
    if (response?.status === RESPONSE_CODE.SUCCESS) {
      setLoading(false);
      handleStep(2);
      // successToast(response.data.meta.message);
    }
    setBtnLoading(false);
  };

  if (loading) {
    return (
      <MDBox sx={{ height: '400px' }}>
        <Loader />
      </MDBox>
    );
  }

  return (
    <MDBox>
      <form>
        <MDBox pt={2}>
          <TableContainer>
            <Table>
              <TableHead sx={{ display: 'table-header-group' }}>
                <TableRow>
                  <TableCell>Category</TableCell>
                  <TableCell>Points</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {category.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <MDTypography variant='body2'>{row.title}</MDTypography>
                    </TableCell>
                    <TableCell sx={{ padding: '4px' }}>
                      <TextField
                        type='number'
                        value={row.total_points}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            'total_points',
                            e.target.value
                          )
                        }
                        InputProps={{
                          sx: { width: '80px', height: '40px' },
                          inputProps: { min: 0 },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={row.is_active}
                        onChange={(e) => handleSwitchChange(index, e)}
                        color='primary'
                      />
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>
                    <MDTypography variant='body2' fontWeight='medium'>
                      Total
                    </MDTypography>
                  </TableCell>
                  <TableCell>
                    <MDTypography variant='body2' fontWeight='medium'>
                      {total}
                    </MDTypography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </MDBox>
        {error ? (
          <MDBox mt={1}>
            <MDAlert color='error'>
              <MDTypography variant='body2' color='white'>
                The total points allocated across all rubric categories must
                equal
                <MDTypography
                  component='a'
                  href='#'
                  variant='body2'
                  fontWeight='medium'
                  color='white'
                >
                  100.
                </MDTypography>
              </MDTypography>
            </MDAlert>
          </MDBox>
        ) : null}
        <MDBox display='flex' justifyContent='flex-end'>
          <MDButton
            variant='gradient'
            color='info'
            sx={{ mt: error ? 0 : 2 }}
            onClick={handleSubmit}
          >
            {btnLoading ? (
              <CircularProgress color='inherit' size='1.3rem' />
            ) : (
              'Save & Next'
            )}
          </MDButton>
        </MDBox>
      </form>
    </MDBox>
  );
}

// Typechecking props of the RubricsCategory
RubricsCategory.propTypes = {
  handleStep: PropTypes.func.isRequired,
  accountId: PropTypes.number.isRequired,
};
export default RubricsCategory;
