import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui material components
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import api from 'api';
// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout';

// common components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import { RESPONSE_CODE } from 'utils/constants';
import { getLocalStorageItem, setLocalStorageItem } from 'utils/helper';
// Images and logo
import bgImage from 'assets/images/bg-sign-in-basic.jpeg';
import rickHouseLogo from 'assets/images/logos/rickhouseLogo.png';

import loginValidation from './validation';

function Basic() {
  // saved credentials
  const credential = JSON.parse(getLocalStorageItem('credentials'));

  // states
  const [inputs, setInputs] = useState({
    email: credential ? credential.email : '',
    password: credential ? credential.password : '',
  });
  const [inputsError, setInputsError] = useState({
    email: null,
    password: null,
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // navigator
  const navigate = useNavigate();

  // handlers
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleInputs = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setInputsError((prevState) => ({
      ...prevState,
      [e.target.name]: null,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { errors, isValid } = loginValidation(inputs);

    if (isValid) {
      setInputsError({
        email: null,
        password: null,
      });
      // const response = await api.login(inputs);

      // if (response?.status === RESPONSE_CODE.SUCCESS) {
      //   setLocalStorageItem('token', response?.data?.data?.access_token);
      //   setLocalStorageItem(
      //     'refresh_token',
      //     response?.data?.data?.refresh_token
      //   );

      //   setLocalStorageItem('userInfo', response?.data?.data);
      //   if (rememberMe) {
      //     localStorage.setItem('credentials', JSON.stringify(inputs));
      //   } else {
      //     localStorage.removeItem('credentials');
      //   }
      //   navigate('/');
      // }
    } else {
      setInputsError(errors);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant='gradient'
          bgColor='info'
          borderRadius='lg'
          coloredShadow='info'
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign='center'
        >
          <MDTypography variant='h4' fontWeight='medium' color='white' mt={1}>
            <MDBox
              px={2}
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              <img src={rickHouseLogo} width={200} />
            </MDBox>
          </MDTypography>
        </MDBox>
        <form>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component='form' role='form'>
              <MDBox mb={2}>
                <MDInput
                  type='email'
                  label='Email'
                  fullWidth
                  name='email'
                  value={inputs.email}
                  onChange={handleInputs}
                  error={!!inputsError.email}
                  helperText={inputsError.email}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type={showPassword ? 'text' : 'password'}
                  label='Password'
                  fullWidth
                  name='password'
                  value={inputs.password}
                  onChange={handleInputs}
                  error={!!inputsError.password}
                  helperText={inputsError.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                          edge='end'
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MDBox>
              <MDBox display='flex' alignItems='center' ml={-1}>
                <Switch
                  checked={rememberMe}
                  onChange={handleSetRememberMe}
                  focusRipple
                  sx={{
                    '& .MuiSwitch-switchBase.Mui-checked': {
                      color: '#ffffff', // Change the thumb color when checked
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.08)',
                      },
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: '#1A73E8 !important', // Change the track color when checked
                    },
                    '& .MuiSwitch-track': {
                      backgroundColor: 'gray', // Change the track color when unchecked
                    },
                  }}
                />
                <MDTypography
                  variant='button'
                  fontWeight='regular'
                  color='text'
                  onClick={handleSetRememberMe}
                  sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  type='submit'
                  variant='gradient'
                  color='info'
                  fullWidth
                  onClick={handleSubmit}
                >
                  sign in
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </form>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
