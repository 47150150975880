import axios from 'axios';

import { getLocalStorageItem } from 'utils/helper';

import { BASE_URL } from '../../utils/config';
import errorHandler from './errorHandler';

export const getAPI = (tag = '') => {
  return axios
    .get(BASE_URL + tag, {
      headers: getLocalStorageItem('token')
        ? {
            Authorization: `Bearer ${getLocalStorageItem('token')}`,
          }
        : {},
    })
    .then((res) => {
      if (res?.data?.meta?.code === 0) {
        throw new Error(res?.data?.meta?.message);
      }
      if (res?.data?.meta?.code === 1) {
        return res;
      }
    })
    .catch((e) => {
      return errorHandler(e);
    });
};

export const postAPI = (tag = '', reqBody, withAuth = true) => {
  return axios
    .post(BASE_URL + tag, reqBody, {
      headers:
        getLocalStorageItem('token') && withAuth
          ? {
              Authorization: `Bearer ${getLocalStorageItem('token')}`,
            }
          : {},
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return errorHandler(e);
    });
};

export const putAPI = (tag = '', reqBody) => {
  return axios
    .put(BASE_URL + tag, reqBody !== null && reqBody, {
      headers: getLocalStorageItem('token')
        ? {
            Authorization: `Bearer ${getLocalStorageItem('token')}`,
          }
        : {},
    })
    .then((res) => {
      if (res?.data?.meta?.code === 0) {
        throw new Error(res?.data?.meta?.message);
      }
      if (res?.data?.meta?.code === 1) {
        return res;
      }
    })
    .catch((e) => {
      return errorHandler(e);
    });
};

export const deleteAPI = (tag = '', reqBody) => {
  return axios
    .delete(BASE_URL + tag, {
      data: reqBody,
      headers: getLocalStorageItem('token')
        ? {
            Authorization: `Bearer ${getLocalStorageItem('token')}`,
          }
        : {},
    })
    .then((res) => {
      if (res?.data?.meta?.code === 0) {
        throw new Error(res?.data?.meta?.message);
      }
      if (res?.data?.meta?.code === 1) {
        return res;
      }
    })
    .catch((e) => {
      return errorHandler(e);
    });
};
