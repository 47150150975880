import React, { useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
// import api from 'api';
import PropTypes from 'prop-types';

import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';

// import { RESPONSE_CODE } from 'utils/constants';
// import { successToast } from 'utils/helper';

import companyValidation from './validation';

function AddCompany({ handleStep, setAccountId }) {
  console.log('🚀 ~ AddCompany ~ setAccountId:', setAccountId);
  const [formValues, setFormValues] = useState({
    title: '',
    email: '',
    industry: '',
    description: '',
    appId: '',
    appSecret: '',
    appAccessToken: '',
    adsAccountId: '',
    appConfigId: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setErrors((prevState) => ({
      ...prevState,
      [name]: null,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { isValid, errors } = companyValidation(formValues);
    if (isValid) {
      // const payload = {
      //   app_id: formValues.appId,
      //   app_secret: formValues.appSecret,
      //   app_access_token: formValues.appAccessToken,
      //   ads_account_id: formValues.adsAccountId,
      //   app_config_id: formValues.appConfigId,
      //   company_name: formValues.title,
      //   company_email: formValues.email,
      //   company_industry: formValues.industry,
      //   company_description: formValues.description,
      // };
      // setLoading(true);
      // const response = await api.addCompany(payload);
      // if (response?.status === RESPONSE_CODE.SUCCESS_NEW_RESOURCE) {
      //   setLoading(false);
      //   handleStep(1);
      //   setAccountId(
      //     response.data.data.ad_accounts
      //       ? response.data.data.ad_accounts[0].id
      //       : null
      //   );
      //   // successToast(response.data.meta.message);
      // }
      // setLoading(false);
    } else {
      setErrors(errors);
    }
  };

  return (
    <MDBox px={3} py={2}>
      <form onSubmit={handleSubmit}>
        {/* Form 1 */}
        <MDTypography variant='h6' gutterBottom>
          Company Information
        </MDTypography>
        <MDBox>
          <MDInput
            label='Title'
            name='title'
            value={formValues.title}
            onChange={handleInputChange}
            error={Boolean(errors.title)}
            helperText={errors.title}
            sx={{ mb: '15px' }}
            fullWidth
            inputProps={{ maxLength: 255 }}
            require={true}
          />
        </MDBox>
        <MDBox>
          <MDInput
            label='Email'
            name='email'
            value={formValues.email}
            onChange={handleInputChange}
            error={Boolean(errors.email)}
            helperText={errors.email}
            sx={{ mb: '15px' }}
            fullWidth
            inputProps={{ maxLength: 255 }}
            require={true}
          />
        </MDBox>
        <MDBox>
          <MDInput
            label='Industry'
            name='industry'
            value={formValues.industry}
            onChange={handleInputChange}
            error={Boolean(errors.industry)}
            helperText={errors.industry}
            sx={{ mb: '15px' }}
            fullWidth
            inputProps={{ maxLength: 255 }}
            require={true}
          />
        </MDBox>
        <MDBox>
          <MDInput
            label='Description'
            name='description'
            value={formValues.description}
            onChange={handleInputChange}
            error={Boolean(errors.description)}
            helperText={errors.description}
            sx={{ mb: '15px' }}
            fullWidth
            inputProps={{ maxLength: 255 }}
            require={true}
          />
        </MDBox>
        {/* Form 2 */}
        <Grid item xs={12} md={6}>
          <MDTypography variant='h6' gutterBottom>
            Configure Facebook App
          </MDTypography>
          <Grid container spacing={2.1}>
            <Grid item xs={12} sm={6}>
              <MDBox>
                <MDInput
                  label='App id'
                  name='appId'
                  value={formValues.appId}
                  onChange={handleInputChange}
                  error={!!errors.appId}
                  helperText={errors.appId}
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                  require={true}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox>
                <MDInput
                  label='App secret'
                  name='appSecret'
                  value={formValues.appSecret}
                  onChange={handleInputChange}
                  error={!!errors.appSecret}
                  helperText={errors.appSecret}
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                  require={true}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox>
                <MDInput
                  label='App access token'
                  name='appAccessToken'
                  value={formValues.appAccessToken}
                  onChange={handleInputChange}
                  error={Boolean(errors.appAccessToken)}
                  helperText={errors.appAccessToken}
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                  require={true}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox>
                <MDInput
                  label='Ads account id'
                  name='adsAccountId'
                  value={formValues.adsAccountId}
                  onChange={handleInputChange}
                  error={Boolean(errors.adsAccountId)}
                  helperText={errors.adsAccountId}
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                  require={true}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox>
                <MDInput
                  label='App config id'
                  name='appConfigId'
                  value={formValues.appConfigId}
                  onChange={handleInputChange}
                  error={Boolean(errors.appConfigId)}
                  helperText={errors.appConfigId}
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                  require={true}
                />
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
        <MDBox display='flex' justifyContent='flex-end'>
          <MDButton
            type='submit'
            variant='gradient'
            color='info'
            sx={{ mt: 2 }}
          >
            {loading ? (
              <CircularProgress color='inherit' size='1.3rem' />
            ) : (
              'Save & Next'
            )}
          </MDButton>
        </MDBox>
      </form>
    </MDBox>
  );
}

// Typechecking props of the MDAlert
AddCompany.propTypes = {
  handleStep: PropTypes.any,
  setAccountId: PropTypes.any,
};

export default AddCompany;
