import { AUTH_API } from './apiModules/authentication';
import { COMPANY_API } from './apiModules/company';
import { RUBRIC_CATEGORY_API } from './apiModules/rubricCategory';

const API = {
  ...AUTH_API,
  ...COMPANY_API,
  ...RUBRIC_CATEGORY_API,
};
export default API;
