// @mui material components
import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Card,
  CardMedia,
  Chip,
  Icon,
  Link,
  Tab,
  Tabs,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/system';
import DefaultProjectCard from 'examples/Cards/ProjectCards/DefaultProjectCard';
// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
// Images
import homeDecor1 from 'assets/images/home-decor-1.jpg';
import homeDecor2 from 'assets/images/home-decor-2.jpg';
import homeDecor3 from 'assets/images/home-decor-3.jpg';
import homeDecor4 from 'assets/images/home-decor-4.jpeg';
import breakpoints from 'assets/theme/base/breakpoints';

import Header from './Header';

function ViewIndividualAd() {
  const [tabsOrientation, setTabsOrientation] = useState('horizontal');
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal');
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener('resize', handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header>
        <Grid container p={2}>
          <MDTypography
            variant='h5'
            fontWeight='bold'
            textTransform='capitalize'
          >
            Performance
          </MDTypography>
          <Grid container spacing={3} pt={1}>
            <Grid item xs={12} md={6} lg={3}>
              <Card>
                <MDBox
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  py={2}
                  px={2}
                >
                  <MDBox
                    variant='gradient'
                    bgColor={'dark'}
                    color={'light'}
                    coloredShadow={'light'}
                    borderRadius='xl'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    width='3rem'
                    height='3rem'
                  >
                    <Icon fontSize='medium' color='inherit'>
                      currency_exchange
                    </Icon>
                  </MDBox>
                  <MDBox textAlign='right' lineHeight={1.25}>
                    <MDTypography
                      variant='button'
                      fontWeight='light'
                      color='text'
                    >
                      Spend
                    </MDTypography>
                    <MDTypography variant='h4'>20</MDTypography>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Card>
                <MDBox
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  py={2}
                  px={2}
                >
                  <MDBox
                    variant='gradient'
                    bgColor={'dark'}
                    color={'light'}
                    coloredShadow={'light'}
                    borderRadius='xl'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    width='3rem'
                    height='3rem'
                  >
                    <Icon fontSize='medium' color='inherit'>
                      approval
                    </Icon>
                  </MDBox>
                  <MDBox textAlign='right' lineHeight={1.25}>
                    <MDTypography
                      variant='button'
                      fontWeight='light'
                      color='text'
                    >
                      Impressions
                    </MDTypography>
                    <MDTypography variant='h4'>30</MDTypography>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Card>
                <MDBox
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  py={2}
                  px={2}
                >
                  <MDBox
                    variant='gradient'
                    bgColor={'dark'}
                    color={'light'}
                    coloredShadow={'light'}
                    borderRadius='xl'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    width='3rem'
                    height='3rem'
                  >
                    <Icon fontSize='medium' color='inherit'>
                      workspaces
                    </Icon>
                  </MDBox>
                  <MDBox textAlign='right' lineHeight={1.25}>
                    <MDTypography
                      variant='button'
                      fontWeight='light'
                      color='text'
                    >
                      Results
                    </MDTypography>
                    <MDTypography variant='h4'>56</MDTypography>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Card>
                <MDBox
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  py={2}
                  px={2}
                >
                  <MDBox
                    variant='gradient'
                    bgColor={'dark'}
                    color={'light'}
                    coloredShadow={'light'}
                    borderRadius='xl'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    width='3rem'
                    height='3rem'
                  >
                    <Icon fontSize='medium' color='inherit'>
                      done_all
                    </Icon>
                  </MDBox>
                  <MDBox textAlign='right' lineHeight={1.25}>
                    <MDTypography
                      variant='button'
                      fontWeight='light'
                      color='text'
                    >
                      Cost Per Result
                    </MDTypography>
                    <MDTypography variant='h4'>30</MDTypography>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid container mt={3} spacing={2}>
          <Grid item sm={4} md={12} lg={4}>
            <Card sx={{ height: '100%' }}>
              <CardMedia
                component='video'
                height='500'
                image='https://rickhouse-analysis.s3.amazonaws.com/Extra+Thrive+Data/_SocialProof_120201925257080716.mp4'
                alt='Paella dish'
                controls
                sx={{
                  borderRadius: '0.75rem',
                  objectFit: 'cover',
                  margin: '20px',
                }}
              />
            </Card>
          </Grid>
          <Grid item sm={4} md={12} lg={4}>
            <Card sx={{ height: '100%' }}>
              <AppBar position='static'>
                <Tabs
                  orientation={tabsOrientation}
                  value={tabValue}
                  onChange={handleSetTabValue}
                >
                  <Tab
                    label='Audio Transcription'
                    icon={
                      <Icon fontSize='small' sx={{ mt: -0.25 }}>
                        play_circle
                      </Icon>
                    }
                  />
                  <Tab
                    label='Visual Transcription'
                    icon={
                      <Icon fontSize='small' sx={{ mt: -0.25 }}>
                        equalizer
                      </Icon>
                    }
                  />
                </Tabs>
                {tabValue == '1' ? (
                  <Card sx={{ padding: '20px', boxShadow: 'none' }}>
                    <MDTypography variant='h5' component='div'>
                      Visual Transcription
                    </MDTypography>
                    <MDTypography variant='body2' color='text' mt={3}>
                      I am obsessed with this shade ✨ Elena ✨ (Bronze Berry
                      Shimmer) Look at that color payoff 👀 it is absolutely
                      stunning! I love the pop and dimension it gives to my
                      whole makeup look Crease-proof ✅ Waterproof ✅
                      Smudge-proof ✅ They last all day long 💋 I love it! 😍
                    </MDTypography>
                  </Card>
                ) : (
                  <Card sx={{ padding: '20px', boxShadow: 'none' }}>
                    <MDTypography variant='h5' component='div'>
                      Audio Transcription
                    </MDTypography>
                    <MDTypography variant='body2' color='text' mt={3}>
                      I am obsessed with the shade Elena.Just look at that color
                      payoff.It is absolutely stunning.I love the pop and
                      dimension it gives to my whole makeup look.They&apos;re
                      crease-proof, waterproof, smudge-proof, and they last all
                      day long.I love it.
                    </MDTypography>
                  </Card>
                )}
              </AppBar>
            </Card>
          </Grid>
          <Grid item sm={4} md={12} lg={4}>
            <Card sx={{ padding: '10px', height: '100%' }}>
              <Card sx={{ padding: '10px', boxShadow: 'none' }}>
                <MDTypography variant='h5' component='div'>
                  Concept Description
                </MDTypography>
                <MDTypography variant='body2' color='text' mt={3}>
                  Stunning Seasonal Shades&apos; Showcases the eyeshadow
                  product&apos;s appeal through a visual demonstration,
                  emphasizing its long-lasting color and waterproof features,
                  paired with a special promotional offer.
                </MDTypography>
                <Stack direction='row' spacing={1} mt={2}>
                  <Chip label='Demonstration' color='error' />
                  <Chip label='Problem-Solution' color='info' />
                  <Chip label='Scarcity' color='warning' />
                </Stack>
              </Card>
              <Card sx={{ height: '100%', width: '100%', boxShadow: 'none' }}>
                <MDBox display='flex' pt={2} px={2}>
                  <MDTypography
                    variant='h6'
                    fontWeight='medium'
                    textTransform='capitalize'
                  >
                    Value Propositions
                  </MDTypography>
                </MDBox>
                <MDBox px={2}>
                  <MDBox opacity={0.5}>
                    <Divider />
                  </MDBox>
                  <MDBox>
                    <MDBox>
                      <Stack
                        direction='row'
                        spacing={2}
                        mt={2}
                        component={'div'}
                      >
                        <Chip label='Confidence boost' color='info' />
                        <Chip label='Easy to use' color='error' />
                      </Stack>
                      <Stack direction='row' spacing={2} mt={2}>
                        <Chip label='Instant result' color='secondary' />
                        <Chip label='Quick boost' color='warning' />
                      </Stack>
                    </MDBox>{' '}
                  </MDBox>
                </MDBox>
              </Card>
            </Card>
          </Grid>
        </Grid>

        <MDBox mt={5} mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} xl={4}>
              <Card sx={{ marginTop: '10px', padding: '20px' }}>
                <MDTypography
                  variant='h5'
                  fontWeight='bold'
                  textTransform='capitalize'
                >
                  Details
                </MDTypography>
                <MDBox opacity={0.5}>
                  <Divider />
                </MDBox>
                <MDBox display='flex' py={1} pr={2}>
                  <MDTypography
                    variant='button'
                    fontWeight='bold'
                    textTransform='capitalize'
                  >
                    Product: &nbsp;
                  </MDTypography>
                  <MDTypography
                    variant='button'
                    color='text'
                    fontWeight='light'
                  >
                    Teeth Whitening Booster Gel
                  </MDTypography>
                </MDBox>
                <MDBox display='flex' py={1} pr={2}>
                  <MDTypography
                    variant='button'
                    fontWeight='bold'
                    textTransform='capitalize'
                  >
                    Lunch Date: &nbsp;
                  </MDTypography>
                  <MDTypography
                    variant='button'
                    color='text'
                    fontWeight='light'
                  >
                    2023-02-02
                  </MDTypography>
                </MDBox>

                <MDBox display='flex' alignItems='center' pr={2}>
                  <MDTypography
                    variant='button'
                    fontWeight='bold'
                    textTransform='capitalize'
                    sx={{ mt: '5px' }}
                  >
                    View on Meta: &nbsp;
                  </MDTypography>
                  <Link
                    href='https://www.facebook.com/act_753196138360184/videos/721837456487777/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {' '}
                    <MDTypography
                      variant='button'
                      color='text'
                      fontWeight='light'
                      style={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      href=''
                    >
                      Click Here
                    </MDTypography>
                  </Link>
                </MDBox>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} xl={4}>
              <Card sx={{ marginTop: '10px' }}>
                <MDBox pt={2} px={2}>
                  <MDTypography
                    variant='h6'
                    fontWeight='medium'
                    textTransform='capitalize'
                  >
                    Talent Demographics
                  </MDTypography>
                </MDBox>
                <MDBox px={2}>
                  <MDBox opacity={0.5}>
                    <Divider />
                  </MDBox>
                  <MDBox>
                    <MDBox pr={2}>
                      <MDTypography
                        variant='button'
                        fontWeight='bold'
                        textTransform='capitalize'
                      >
                        Age group: &nbsp;
                      </MDTypography>
                      <MDTypography
                        variant='button'
                        color='text'
                        fontWeight='light'
                      >
                        Adult
                      </MDTypography>
                    </MDBox>
                    <MDBox pr={2}>
                      <MDTypography
                        variant='button'
                        fontWeight='bold'
                        textTransform='capitalize'
                      >
                        Ethnicity: &nbsp;
                      </MDTypography>
                      <MDTypography
                        variant='button'
                        color='text'
                        fontWeight='light'
                      >
                        Mixed or Multiracial
                      </MDTypography>
                    </MDBox>
                    <MDBox pr={2}>
                      <MDTypography
                        variant='button'
                        fontWeight='bold'
                        textTransform='capitalize'
                      >
                        Gender: &nbsp;
                      </MDTypography>
                      <MDTypography
                        variant='button'
                        color='text'
                        fontWeight='light'
                      >
                        Female
                      </MDTypography>
                    </MDBox>
                    <MDBox pr={2}>
                      <MDTypography
                        variant='button'
                        fontWeight='bold'
                        textTransform='capitalize'
                      >
                        Notable features: &nbsp;
                      </MDTypography>
                      <MDTypography
                        variant='button'
                        color='text'
                        fontWeight='light'
                      >
                        Curly hair
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} xl={4} sx={{ display: 'flex' }}>
              <Card sx={{ padding: '20px' }}>
                <MDTypography variant='h5' component='div'>
                  Visual Description
                </MDTypography>
                <MDBox opacity={0.5}>
                  <Divider />
                </MDBox>
                <MDTypography variant='body2' color='text' mt={3}>
                  Need that confidence boost in the morning? Well, here&apos;s
                  the best way to do so.Snow&apos;s Teeth Whitening Booster Gel
                  is literally a lifesaver.
                </MDTypography>
              </Card>
              <Divider orientation='vertical' sx={{ mx: 0 }} />
            </Grid>
          </Grid>
        </MDBox>

        <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant='h6' fontWeight='medium'>
            Similar
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant='button' color='text'>
              similar ads
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox p={2}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor1}
                label='Snow Teeth Whitening'
                title='modern'
                description='As Uber works through a huge amount of internal management turmoil.'
                action={{
                  type: 'internal',
                  route: '/pages/profile/profile-overview',
                  color: 'info',
                  label: 'view Ad',
                }}
                authors={[]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor2}
                label='Snow Teeth Whitening'
                title='scandinavian'
                description='Music is something that everyone has their own specific opinion about.'
                action={{
                  type: 'internal',
                  route: '/pages/profile/profile-overview',
                  color: 'info',
                  label: 'view ad',
                }}
                authors={[]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor3}
                label='Snow Teeth Whitening'
                title='minimalist'
                description='Different people have different taste, and various types of music.'
                action={{
                  type: 'internal',
                  route: '/pages/profile/profile-overview',
                  color: 'info',
                  label: 'view ad',
                }}
                authors={[]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor4}
                label='Snow Teeth Whitening'
                title='gothic'
                description='Why would anyone pick blue over pink? Pink is obviously a better color.'
                action={{
                  type: 'internal',
                  // route: '/pages/profile/profile-overview',
                  // color: 'info',
                  // label: 'view ad',
                }}
                authors={[]}
              />
            </Grid>
          </Grid>
        </MDBox>
      </Header>
    </DashboardLayout>
  );
}

export default ViewIndividualAd;
