// Material Dashboard 2 React components
import React from 'react';
import { Icon, Switch } from '@mui/material';
// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// MUI custom components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
// Images
import team2 from 'assets/images/team-2.jpg';
import team3 from 'assets/images/team-3.jpg';
import team4 from 'assets/images/team-4.jpg';

const Author = ({ name, email }) => (
  <MDBox display='flex' alignItems='center' lineHeight={1}>
    <MDBox ml={0} lineHeight={1}>
      <MDTypography display='block' variant='button' fontWeight='medium'>
        {name}
      </MDTypography>
      <MDTypography variant='caption'>{email}</MDTypography>
    </MDBox>
  </MDBox>
);
Author.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};
export default function data() {
  return {
    columns: [
      {
        Header: 'Company name',
        accessor: 'author',
        width: '15%',
        align: 'left',
      },
      { Header: 'Status', accessor: 'status', align: 'center' },
      { Header: 'Action', accessor: 'action', align: 'left' },
    ],

    rows: [
      {
        author: (
          <Author
            image={team2}
            name='John Michael'
            email='john@creative-tim.com'
          />
        ),
        status: <Switch defaultChecked color='success' />,
        action: (
          <>
            <MDTypography component='a' href='#' color='text' px={1}>
              <Icon>visibility</Icon>
            </MDTypography>
            <MDTypography component='a' href='#' color='text'>
              <Icon>edit</Icon>
            </MDTypography>
          </>
        ),
      },
      {
        author: (
          <Author
            image={team3}
            name='John Michael'
            email='john@creative-tim.com'
          />
        ),
        status: <Switch defaultChecked />,
        action: (
          <>
            <MDTypography component='a' href='#' color='text' px={1}>
              <Icon>visibility</Icon>
            </MDTypography>
            <MDTypography component='a' href='#' color='text'>
              <Icon>edit</Icon>
            </MDTypography>
          </>
        ),
      },
      {
        author: (
          <Author
            image={team4}
            name='John Michael'
            email='john@creative-tim.com'
          />
        ),
        status: <Switch defaultChecked />,
        action: (
          <>
            <MDTypography component='a' href='#' color='text' px={1}>
              <Icon>visibility</Icon>
            </MDTypography>
            <MDTypography component='a' href='#' color='text'>
              <Icon>edit</Icon>
            </MDTypography>
          </>
        ),
      },
    ],
  };
}
