import React, { useEffect, useState } from 'react';
import { Icon } from '@mui/material';
// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import logo from 'assets/images/logos/TC_logo_.png';
// Material Dashboard 2 React base styles
import breakpoints from 'assets/theme/base/breakpoints';

function Header({ children }) {
  const [tabsOrientation, setTabsOrientation] = useState('horizontal');

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal');
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener('resize', handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation);
  }, [tabsOrientation]);

  return (
    <MDBox position='relative' mb={2}>
      <Card
        sx={{
          position: 'relative',
          mt: 2,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <MDBox>
          <Grid container spacing={1}>
            <Grid item sm={12} md={12} lg={11}>
              <MDBox display='flex' alignItems='center'>
                <MDBox
                  padding='10px'
                  borderRadius='50%'
                  mr={2}
                  border={'1px red'}
                >
                  <img src={logo} alt='logo' width={'50px'} height={'40px'} />
                </MDBox>
                <MDBox>
                  <MDTypography
                    variant='h5'
                    fontWeight='medium'
                    textTransform='capitalize'
                    sx={{ lineHeight: '1' }}
                  >
                    Thrive Causemetics
                  </MDTypography>
                  <MDTypography
                    variant='text'
                    fontWeight='light'
                    sx={{ fontSize: '15px' }}
                  >
                    Infinity Waterproof Eyeshadow Stick
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item sm={12} md={12} lg={1}>
              <Card>
                <MDBox
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  py={1}
                  px={1}
                >
                  <MDBox
                    variant='gradient'
                    bgColor={'dark'}
                    color={'light'}
                    coloredShadow={'light'}
                    borderRadius='xl'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    width='2rem'
                    height='2rem'
                  >
                    <Icon fontSize='medium' color='inherit'>
                      trending_up
                    </Icon>
                  </MDBox>
                  <MDBox textAlign='right' lineHeight={1.25} ml='20px'>
                    <MDTypography
                      variant='button'
                      fontWeight='light'
                      color='text'
                    >
                      Hit ✅
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: '',
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
