export const BACKEND_URL = 'http://192.168.1.169:8002';

export const RESPONSE_CODE = {
  SUCCESS: 200,
  SUCCESS_NEW_RESOURCE: 201,
  SUCCESS_WITHOUT_RESPONSE: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  FORBIDDEN: 403,
  INTERNAL_SERVER: 500,
  SERVICE_UNAVAILABLE: 503,
};

export const ADD_COMPANY_LABELS = [
  'Company configuration',
  'Rubric category configuration',
  'Congratulations',
];
