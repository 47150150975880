import React, { useState } from 'react';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { ADD_COMPANY_LABELS } from 'utils/constants';

import AddCompany from './companyConfig';
import RubricsCategory from './rubricsCategory';
import Success from './success';

export default function StepForm() {
  const [activeStep, setActiveStep] = useState(0);
  const [accountId, setAccountId] = useState(null);

  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <AddCompany handleStep={setActiveStep} setAccountId={setAccountId} />
        );
      case 1:
        return (
          <RubricsCategory handleStep={setActiveStep} accountId={accountId} />
        );
      case 2:
        return <Success />;
      default:
        throw new Error('Unknown step');
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox display='flex' justifyContent='center' alignItems='center'>
          <MDBox width='70%'>
            <MDBox sx={{ my: 2 }}>
              <MDTypography variant='h4'>Company setup</MDTypography>
            </MDBox>
            <Stepper
              activeStep={activeStep}
              sx={{ py: 2, mb: 2 }}
              alternativeLabel
            >
              {ADD_COMPANY_LABELS.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {handleSteps(activeStep)}
          </MDBox>
        </MDBox>
      </DashboardLayout>
    </>
  );
}
