import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import MDBox from 'components/MDBox';

export default function Loader() {
  return (
    <MDBox
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <CircularProgress color='inherit' size='2.3rem' />
    </MDBox>
  );
}
