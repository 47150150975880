import React from 'react';
import Icon from '@mui/material/Icon';

const data = [
  {
    name: 'Dashboard ',
    key: 'dashboard',
    icon: <Icon fontSize='small'>dashboard</Icon>,
    route: '/dashboard',
  },

  {
    name: 'Ads Analysis',
    key: 'ads-analysis',
    icon: <Icon fontSize='small'>check</Icon>,
    route: '/ads-analysis',
  },

  {
    name: 'Opportunity Score History',
    key: 'opportunity-score-history',
    icon: <Icon fontSize='small'>history</Icon>,
    route: '/opportunity-score-history',
  },

  {
    name: 'Top Recommendations',
    key: 'top-recommendations',
    icon: <Icon fontSize='small'>recommendation</Icon>,
    route: '/top-recommendations',
  },

  {
    name: 'Configuration',
    icon: <Icon fontSize='small'>settings</Icon>,
    subMenu: [
      {
        name: 'Companies',
        key: 'companies',
        icon: <Icon fontSize='small'>business</Icon>,
        route: '/companies',
      },
      {
        name: 'Other Setup',
        key: 'other-setup',
        icon: <Icon fontSize='small'>tune</Icon>,
        route: '/other-setup',
      },
    ],
  },

  {
    name: 'Meta Data Sync',
    key: 'meta-data-sync',
    icon: <Icon fontSize='small'>storage</Icon>,
    subMenu: [
      {
        name: 'Data Sync',
        key: 'data-sync',
        icon: <Icon fontSize='small'>sync</Icon>,
        route: '/data-sync',
      },
      {
        name: 'History',
        key: 'sync-history',
        icon: <Icon fontSize='small'>history</Icon>,
        route: '/sync-history',
      },
    ],
  },
];

export default data;
