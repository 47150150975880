import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'examples/Tables/DataTable';

import MDBadge from 'components/MDBadge';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';

function DataSync() {
  const [company, setCompany] = React.useState('');
  const [client, setClient] = React.useState('');
  const [year, setYear] = React.useState('');

  const handleCompanyChange = (event) => {
    setCompany(event.target.value);
  };

  const handleClientChange = (event) => {
    setClient(event.target.value);
  };
  const handleYearChange = (event) => {
    setYear(event.target.value);
  };
  const columns = [
    { Header: 'Sync date', accessor: 'syncDate', width: '15%', align: 'left' },
    {
      Header: 'Company name',
      accessor: 'name',
      width: '15%',
      align: 'left',
    },
    { Header: 'Date Range', accessor: 'date', width: '15%', align: 'left' },
    { Header: 'Sync Status', accessor: 'status', width: '15%', align: 'left' },
  ];
  const rows = [
    {
      syncDate: '2021-03-02',
      name: <MDTypography variant='caption'>Adidas</MDTypography>,
      date: '2021-03-02 - 2021-04-03',
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent='completed'
            color='success'
            variant='gradient'
            size='sm'
          />
        </MDBox>
      ),
    },
    {
      syncDate: '2021-03-02',
      name: <MDTypography variant='caption'>Adidas</MDTypography>,
      date: '2021-03-02 - 2021-04-03',
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent='pending'
            color='warning'
            variant='gradient'
            size='sm'
          />
        </MDBox>
      ),
    },
    {
      syncDate: '2021-03-02',
      name: <MDTypography variant='caption'>Adidas</MDTypography>,
      date: '2021-03-02 - 2021-04-03',
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent='fail'
            color='error'
            variant='gradient'
            size='sm'
          />
        </MDBox>
      ),
    },
    {
      syncDate: '2021-03-02',
      name: <MDTypography variant='caption'>Adidas</MDTypography>,
      date: '2021-03-02 - 2021-04-03',
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent='pending'
            color='warning'
            variant='gradient'
            size='sm'
          />
        </MDBox>
      ),
    },
    {
      syncDate: '2021-03-02',
      name: <MDTypography variant='caption'>Adidas</MDTypography>,
      date: '2021-03-02 - 2021-04-03',
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent='completed'
            color='succ92ess'
            variant='gradient'
            size='sm'
          />
        </MDBox>
      ),
    },
    {
      syncDate: '2021-03-02',
      name: <MDTypography variant='caption'>Adidas</MDTypography>,
      date: '2021-03-02 - 2021-04-03',
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent='completed'
            color='success'
            variant='gradient'
            size='sm'
          />
        </MDBox>
      ),
    },
    {
      syncDate: '2021-03-02',
      name: <MDTypography variant='caption'>Adidas</MDTypography>,
      date: '2021-03-02 - 2021-04-03',
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent='pending'
            color='warning'
            variant='gradient'
            size='sm'
          />
        </MDBox>
      ),
    },
    {
      syncDate: '2021-03-02',
      name: <MDTypography variant='caption'>Adidas</MDTypography>,
      date: '2021-03-02 - 2021-04-03',
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent='fail'
            color='error'
            variant='gradient'
            size='sm'
          />
        </MDBox>
      ),
    },
    {
      syncDate: '2021-03-02',
      name: <MDTypography variant='caption'>Adidas</MDTypography>,
      date: '2021-03-02 - 2021-04-03',
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent='pending'
            color='warning'
            variant='gradient'
            size='sm'
          />
        </MDBox>
      ),
    },
    {
      syncDate: '2021-03-02',
      name: <MDTypography variant='caption'>Adidas</MDTypography>,
      date: '2021-03-02 - 2021-04-03',
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent='completed'
            color='succ92ess'
            variant='gradient'
            size='sm'
          />
        </MDBox>
      ),
    },
    {
      syncDate: '2021-03-02',
      name: <MDTypography variant='caption'>Adidas</MDTypography>,
      date: '2021-03-02 - 2021-04-03',
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent='completed'
            color='succ92ess'
            variant='gradient'
            size='sm'
          />
        </MDBox>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox display='flex' py={2} px={5}>
        <FormControl sx={{ height: '45px', width: '18%', margin: '0px 10px' }}>
          <InputLabel id='select-company-label'>Brand</InputLabel>
          <Select
            labelId='select-company-label'
            id='select-company'
            value={company}
            label='Company'
            sx={{ height: '100%' }}
            onChange={handleCompanyChange}
          >
            <MenuItem value={'Company1'}>Adidas</MenuItem>
            <MenuItem value={'Company2'}>Puma</MenuItem>
            <MenuItem value={'Company3'}>Gucci</MenuItem>
            <MenuItem value={'Company3'}>MI</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          sx={{ height: '45px', width: '18%', paddingRight: '10px' }}
        >
          <InputLabel id='select-company-label'>Month</InputLabel>
          <Select
            labelId='select-company-label'
            id='select-company'
            value={client}
            label='client'
            sx={{ height: '100%' }}
            onChange={handleClientChange}
          >
            <MenuItem value={'Company1'}>January</MenuItem>
            <MenuItem value={'Company2'}>February</MenuItem>
            <MenuItem value={'Company3'}>March</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          sx={{ height: '45px', width: '18%', paddingRight: '10px' }}
        >
          <InputLabel id='select-company-label'>Year</InputLabel>
          <Select
            labelId='select-company-label'
            id='select-company'
            value={year}
            label='year'
            sx={{ height: '100%' }}
            onChange={handleYearChange}
          >
            <MenuItem value={'Company1'}>2024</MenuItem>
            <MenuItem value={'Company2'}>2023</MenuItem>
            <MenuItem value={'Company3'}>2022</MenuItem>
          </Select>
        </FormControl>
        <MDButton variant='gradient' color='info'>
          Sync
        </MDButton>
      </MDBox>
      <MDBox width='70%' px={5} py={1}>
        <MDTypography variant='h6' gutterBottom>
          Sync details
        </MDTypography>
        <DataTable
          table={{ columns, rows }}
          isSorted={true}
          entriesPerPage={true}
          showTotalEntries={true}
          canSearch={false}
          noEndBorder={false}
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default DataSync;
