export const pieChartData = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: {
    label: 'Color',
    data: [12, 19, 3, 5, 2, 3],
    backgroundColors: [
      'primary',
      'secondary',
      'info',
      'Green',
      'success',
      'error',
    ],
    borderColor: [
      'rgba(255, 99, 132, 1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(153, 102, 255, 1)',
      'rgba(255, 159, 64, 1)',
    ],
    borderWidth: 1,
  },
};

export const productPieChartData = {
  labels: [
    'Brilliant Eye Brightener',
    'Liquid Lash Extensions Mascara',
    'Sheer Strength Hydrating Lip Tint',
    'Infinity Waterproof Eyeshadow Stick',
    'Instant Brow Fix Semi-Permanent Eyebrow Gel',
    'Bundles',
    'Other',
    'Hydrating Lip Tint',
  ],
  datasets: {
    label: 'Percentage',
    data: [35, 16, 12, 12, 11, 6, 6, 2],
    backgroundColors: [
      'primary',
      'secondary',
      'info',
      'Green',
      'success',
      'error',
    ],
    borderColor: [
      'rgba(255, 99, 132, 1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(153, 102, 255, 1)',
      'rgba(255, 159, 64, 1)',
    ],
    borderWidth: 1,
  },
};

export const videoStaticChartData = {
  labels: ['Video', 'Static'],
  datasets: {
    label: 'Percentage',
    data: [85.57, 14.43],
    backgroundColors: ['primary', 'secondary'],
    borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
    borderWidth: 1,
  },
};
export const aspectChartData = {
  labels: ['4x5', '1x1', '16x9'],
  datasets: {
    label: 'Percentage',
    data: [56.7, 25.77, 17.53],
    backgroundColors: ['primary', 'secondary', 'info'],
    borderColor: [
      'rgba(255, 99, 132, 1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
    ],
    borderWidth: 1,
  },
};
export const uniqueIterationChartData = {
  labels: ['Unique', 'Iterations'],
  datasets: {
    label: 'Percentage',
    data: [19.59, 80.41],
    backgroundColors: ['primary', 'secondary'],
    borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
    borderWidth: 1,
  },
};
export const productTypePieChartData = {
  labels: ['Commercial', 'Other', 'UGC'],
  datasets: {
    label: 'Percentage',
    data: [48.12, 27.07, 24.81],
    backgroundColors: ['primary', 'secondary', 'info', 'Green', 'success'],
    borderColor: [
      'rgba(255, 99, 132, 1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(153, 102, 255, 1)',
    ],
    borderWidth: 1,
  },
};

export const ageChartData = {
  labels: ['Adult', 'Young Adult', 'Middle-Aged Adult', 'Senior'],
  datasets: {
    label: 'Percentage',
    data: [47.37, 26.32, 21.05, 5.26],
    backgroundColors: ['primary', 'secondary', 'info', 'Green'],
    borderColor: [
      'rgba(255, 99, 132, 1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(153, 102, 255, 1)',
      'rgba(255, 159, 64, 1)',
    ],
    borderWidth: 1,
  },
};

export const genderChartData = {
  labels: ['Male', 'Female', 'Other'],
  datasets: {
    label: 'Percentage',
    data: [0, 100, 0],
    backgroundColors: ['primary', 'secondary', 'info'],
    borderColor: [
      'rgba(255, 99, 132, 1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
    ],
  },
};
