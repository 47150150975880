/* eslint-disable react/prop-types */
// @mui material components
import React, { useEffect, useState } from 'react';
import { Switch } from '@mui/material';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import api from 'api';
// common layout
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
// data table
import DataTable from 'examples/Tables/DataTable';

// common components components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Data
import authorsData from './data/authorsTableData';

function Companies() {
  const { rows, columns } = authorsData();

  // const columns = [
  //   {
  //     Header: 'Company name',
  //     accessor: 'author',
  //     width: '15%',
  //     align: 'left',
  //   },

  //   {
  //     Header: 'Status',
  //     accessor: 'status',
  //     align: 'center',
  //     Cell: ({ row }) => (
  //       <Switch
  //         checked={row.original.status}
  //         onChange={() => handleSwitchChange(row.index, row.original.active)}
  //       />
  //     ),
  //   },
  //   {
  //     Header: 'Action',
  //     accessor: 'action2',
  //     align: 'left',
  //   },
  // ];
  // const [rows, setRows] = useState([]);

  // useEffect(() => {
  //   const fetchList = async () => {
  //     const response = await api.getAllCompany();
  //     const pRows = response.data.data.map((row) => {
  //       return {
  //         author: (
  //           <MDBox display='flex' alignItems='center' lineHeight={1}>
  //             <MDBox ml={0} lineHeight={1}>
  //               <MDTypography
  //                 display='block'
  //                 variant='button'
  //                 fontWeight='medium'
  //               >
  //                 {row.account_name}
  //               </MDTypography>
  //               <MDTypography variant='caption'>
  //                 {row.account_email}
  //               </MDTypography>
  //             </MDBox>
  //           </MDBox>
  //         ),
  //         status: true,
  //         action: true,
  //       };
  //     });
  //     setRows(pRows);
  //   };
  //   fetchList();
  // }, []);

  // const handleSwitchChange = async (rowIndex, currentStatus) => {
  //   const newStatus = !currentStatus;

  //   try {
  //     // await api.updateCompanyStatus(rowIndex, newStatus); // Adjust this based on your API
  //     const updatedRows = rows.map((row, index) =>
  //       index === rowIndex ? { ...row, status: newStatus } : row
  //     );
  //     setRows(updatedRows);
  //   } catch (error) {
  //     console.error('Error updating status:', error);
  //   }
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={2}
                px={2}
                variant='gradient'
                bgColor='info'
                borderRadius='lg'
                coloredShadow='info'
              >
                <MDTypography variant='h6' color='white'>
                  Companies
                </MDTypography>
              </MDBox>
              <MDBox pt={1}>
                <DataTable
                  table={{ columns: columns, rows: rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  canSearch={true}
                  noEndBorder={true}
                  addButton={true}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Companies;
