import React from 'react';
import { Card, FormControl } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import logo from 'assets/images/logos/TC_logo_.png';

export default function MDfilter({ width }) {
  const [year, setYear] = React.useState(null);

  const handleYearChange = (newValue) => {
    setYear(newValue);
  };

  return (
    <Card sx={{ height: '100%', mt: '20px', mb: '20px' }}>
      <MDBox display='flex' justifyContent='space-between' alignItems='center'>
        <MDBox display='flex' justifyContent='center' alignItems='center' p={1}>
          <MDBox padding='10px' borderRadius='50%' mr={2} border={'1px red'}>
            <img src={logo} alt='logo' width={'50px'} height={'40px'} />
          </MDBox>
          <MDBox>
            <MDTypography
              variant='h5'
              fontWeight='medium'
              textTransform='capitalize'
            >
              Thrive Causemetics
            </MDTypography>
          </MDBox>
        </MDBox>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FormControl sx={{ width: width, margin: '0px 10px 0px 0px' }}>
            <DatePicker
              views={['month', 'year']}
              label='Month & Year'
              value={year}
              onChange={handleYearChange}
              slotProps={{
                openPickerButton: {
                  color: 'black',
                },
                textField: { fullWidth: true },
              }}
            />
          </FormControl>
        </LocalizationProvider>
      </MDBox>
    </Card>
  );
}

MDfilter.defaultProps = {
  width: '15%',
};

// Typechecking props for the MDInput
MDfilter.propTypes = {
  width: PropTypes.string,
};
