export default {
  sales: {
    labels: ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: {
      label: 'Mobile apps',
      data: [50, 40, 300, 320, 500, 350, 200, 230, 500],
    },
  },
  tasks: {
    labels: ['Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: {
      label: 'Views',
      data: [200, 40, 300, 10, 500, 250, 400, 230, 500],
    },
  },
};
