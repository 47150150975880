import React from 'react';
// Material Dashboard 2 React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DataTable from 'examples/Tables/DataTable';

import MDBadge from 'components/MDBadge';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

function DataSyncHistory() {
  const columns = [
    { Header: 'Sync date', accessor: 'syncDate', width: '15%', align: 'left' },
    {
      Header: 'Company name',
      accessor: 'name',
      width: '25%',
      align: 'left',
    },
    { Header: 'Date Range', accessor: 'date', width: '15%', align: 'left' },
    { Header: 'Sync Status', accessor: 'status', width: '15%', align: 'left' },
  ];
  const rows = [
    {
      syncDate: '2021-03-02',
      name: <MDTypography variant='caption'>Adidas</MDTypography>,
      date: '2021-03-02 - 2021-04-03',
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent='completed'
            color='success'
            variant='gradient'
            size='sm'
          />
        </MDBox>
      ),
    },
    {
      syncDate: '2021-03-02',
      name: <MDTypography variant='caption'>Puma</MDTypography>,
      date: '2021-03-02 - 2021-04-03',
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent='pending'
            color='warning'
            variant='gradient'
            size='sm'
          />
        </MDBox>
      ),
    },
    {
      syncDate: '2021-03-02',
      name: <MDTypography variant='caption'>Nike</MDTypography>,
      date: '2021-03-02 - 2021-04-03',
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent='fail'
            color='error'
            variant='gradient'
            size='sm'
          />
        </MDBox>
      ),
    },
    {
      syncDate: '2021-03-02',
      name: <MDTypography variant='caption'>MI</MDTypography>,
      date: '2021-03-02 - 2021-04-03',
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent='pending'
            color='warning'
            variant='gradient'
            size='sm'
          />
        </MDBox>
      ),
    },
    {
      syncDate: '2021-03-02',
      name: <MDTypography variant='caption'>300Minds</MDTypography>,
      date: '2021-03-02 - 2021-04-03',
      status: (
        <MDBox ml={-1}>
          <MDBadge
            badgeContent='completed'
            color='success'
            variant='gradient'
            size='sm'
          />
        </MDBox>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox width='80%' px={5} py={3}>
        <DataTable
          table={{ columns, rows }}
          isSorted={true}
          entriesPerPage={true}
          showTotalEntries={true}
          canSearch={true}
          noEndBorder={false}
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default DataSyncHistory;
