import toast from 'react-hot-toast';

/* Get Local Storage Item Function */
export const getLocalStorageItem = (key) => {
  return typeof window !== 'undefined' ? localStorage.getItem(key) : null;
};

/* Token Validation Function */
export const checkTokenValid = () => {
  let token = getLocalStorageItem('token');

  return token || false;
};

/* set Local Storage Item Function */
export const setLocalStorageItem = (key, value) => {
  const parsedValue = typeof value !== 'string' ? JSON.stringify(value) : value;

  return typeof window !== 'undefined'
    ? localStorage.setItem(key, parsedValue)
    : null;
};

/* remove Local Storage Item Function */
export const removeLocalStorageItem = (key) => {
  return typeof window !== 'undefined' ? localStorage.removeItem(key) : null;
};

/* clear Local Storage Function */
export const clearLocalStorage = () => {
  for (let i = localStorage.length - 1; i >= 0; i--) {
    const key = localStorage.key(i);

    if (key !== 'credentials') {
      localStorage.removeItem(key);
    }
  }
};

/* error toast Function */
export const errorToast = (
  msg = 'Something went wrong',
  toastId = '',
  duration = 2000
) =>
  toast.error(msg, {
    duration,
    position: 'top-right',
    id: toastId,
    style: {
      borderRadius: '5px',
      background: '#344767',
      color: '#fff',
      fontSize: '16px',
    },
  });

/* success toast Function */
export const successToast = (
  msg = 'Something went wrong',
  toastId = '',
  duration = 2000
) =>
  toast.success(msg, {
    duration,
    position: 'top-right',
    id: toastId,
    style: {
      borderRadius: '5px',
      background: '#344767',
      color: '#fff',
      fontSize: '16px',
    },
  });
