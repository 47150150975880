import { getAPI, postAPI } from '../apiMethods';

export const COMPANY_API = {
  getAllCompany: () =>
    getAPI(`/api/v1/facebook-meta-ads/facebook-ads-account/`),
  addCompany: (reqBody) =>
    postAPI(
      `/api/v1/facebook-meta-ads/facebook-ads-account/add-ad-account`,
      reqBody
    ),
  addRubricCategory: (reqParam, reqBody) =>
    postAPI(
      `/api/v1/facebook-meta-ads/rubric_categories/assign-category-to-account/${reqParam.acc_id}`,
      reqBody
    ),
};
