import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { checkTokenValid } from 'utils/helper.js';

const ProtectedRoute = () => {
  const location = useLocation();
  if (!checkTokenValid()) {
    return <Navigate to='/sign-in' replace />;
  }

  if (location.pathname === '/') return <Navigate to='/sign-in' replace />;

  return <Outlet />;
};

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  component: PropTypes.object,
  children: PropTypes.node,
};
