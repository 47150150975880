import React from 'react';
// layouts
import SignIn from 'layouts/authentication/sign-in';
import Stepper from 'layouts/company/addCompany';
import Companies from 'layouts/company/listing';
import Dashboard from 'layouts/dashboard';
import DataSync from 'layouts/dataSync';
import DataSyncHistory from 'layouts/dataSync/syncHistory';
import Tables from 'layouts/individualAds';
import Profile from 'layouts/individualAds/viewIndividualAd';
import History from 'layouts/opportunityScoreHistory';
import Recommendation from 'layouts/recommendation';

const routes = [
  {
    name: 'Dashboard ',
    route: '/dashboard',
    component: <Dashboard />,
    private: false,
  },
  {
    name: 'Ads Analysis',
    route: '/ads-analysis',
    component: <Tables />,
    private: false,
  },
  {
    name: 'View individual ads',
    route: '/individual-ad-analysis/view',
    component: <Profile />,
    private: false,
  },
  {
    name: 'Companies',
    route: '/companies',
    component: <Companies />,
    private: false,
  },
  {
    name: 'Add company',
    route: '/companies/add-company',
    component: <Stepper />,
    private: false,
  },
  {
    name: 'Opportunity Score History',
    route: '/opportunity-score-history',
    component: <History />,
    private: false,
  },
  {
    name: 'Data Sync',
    route: '/data-sync',
    component: <DataSync />,
    private: false,
  },
  {
    name: 'Top Recommendations',
    route: '/top-recommendations',
    component: <Recommendation />,
    private: false,
  },
  {
    name: 'Data sync history',
    route: '/sync-history',
    component: <DataSyncHistory />,
    private: false,
  },
  {
    name: 'Sign In',
    route: '/sign-in',
    component: <SignIn />,
    private: false,
  },
];

export default routes;
